
import React, { useState } from 'react'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchUser } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import ModalLayout from 'components/utils/ModalLayout'
import BankOptions from 'components/utils/BankOptions'
import { useNavigate } from 'react-router-dom'

export default function WithdrawCashback({ closeView }) {
  const [loading, setLoading] = useState(false)
  const { user } = useSelector(state => state.data)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [forms, setForms] = useState({
    tag: '',
    bankname: '',
    accname: '',
    accnumber: '',
    amount: '',
    options: 'bank'
  })

  const handleForms = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
  }


  const SpendCash = async () => {
    if (!forms.bankname) return ErrorAlert('Select your preferred bank')
    if (!forms.accname) return ErrorAlert('provide account name')
    if (!forms.accnumber) return ErrorAlert('provide account number')
    if (!forms.amount) return ErrorAlert('provide amount to withdraw')
    if (forms.amount < 1) return ErrorAlert(`provide a valid withdrawable amount`)
    if (forms.amount > user.coinbal) return ErrorAlert(`Insufficient balance on your cashback`)
    if (forms.amount < 100) return ErrorAlert(`withdrawal amount must be at least ${NairaSign}100 and above`)
    const data = {
      ...forms,
    }
    setLoading(true)
    try {
      const res = await Authposturl(Apis.mobilenig.open_cashback_withdrawal, data)
      if (res.status === 200) {
        SuccessAlert(res.message)
        dispatch(dispatchUser(res.user))
        return navigate('/cashbacks')
      } else {
        ErrorAlert(res.message)
      }
    } catch (error) {
      Errors(error)
    } finally {
      setLoading(false)
    }
  }
  const setup = val => {
    setForms({
      ...forms,
      bankname: val.bank_name
    })
  }

  return (
    <ModalLayout closeView={closeView}>
      <div className='mb-6 rounded-lg p-3'>
        <div className="font-bold">Open a withdrawal order</div>
      <div className="text-sm text-zinc-500">Available Cashback <span className="span text-green-600 font-bold text-lg">{NairaSign}{user.coinbal?.toLocaleString()}</span> </div>
      <div className="bg-orange-200/50 p-2 rounded-lg text-sm text-orange-700 border border-orange-300 my-5">...Withdrawal only allowed from {NairaSign}100 and above...</div>
        <div className="mb-4 relative">
          <div className="">Commercial Institution</div>
          <BankOptions defaultValue={forms.bankname} title="--Select--" setup={setup} />
        </div>
        <div className="mb-3">
          <div className="text-sm">Account Holder Name</div>
          <Forminput
            value={forms.accname}
            formName={'accname'}
            onChange={handleForms}
          />
        </div>
        <div className="mb-3">
          <div className="text-sm">Account Number</div>
          <Forminput
            value={forms.accnumber}
            formName={'accnumber'}
            onChange={handleForms}
          />
        </div>
        <div className="mb-3">
          <div className="text-sm">Amount to withdraw ({NairaSign})</div>
          <Forminput
            value={forms.amount}
            onChange={handleForms}
            formName={'amount'}
            label={'0.00'}
            type="number"
          />
        </div>
        <Formbutton
          handleSubmission={SpendCash}
          loading={loading}
          title={'withdraw'}
        />
      </div>
    </ModalLayout>
  )
}


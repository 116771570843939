
import { useQuery } from "@tanstack/react-query";
import { Apis, Authgeturl } from "components/Apis";
import UserLayout from "components/user/UserLayout";
import Spinner from "components/utils/Spinner";
import { NairaSign } from "components/utils/functions";
import moment from "moment";
import React from "react";
import { LuNetwork } from "react-icons/lu";
import { SlGlobe } from "react-icons/sl";

export default function BizTransactions() {
  const { data, isLoading} = useQuery({
    queryKey: ['biz_transactions'],
    queryFn: async () => {
      const response = await Authgeturl(Apis.user.account_plan_transactions)
      return response;
    },
  })
  if (isLoading) return (
    <UserLayout title="Biz Transactions" navlink={'/biznet'} footer={false}>
      <div className="w-fit mx-autp">
        <Spinner />
      </div>
    </UserLayout>
  )
  return (
    <UserLayout title="Biz Transactions" navlink={'/biznet'} footer={false}>
      <div className="w-11/12 mx-auto">
        <div className="">
          {data.total < 1 && <div>
            <div className="text-center">No Transaction recorded...</div>
          </div>}
          {data.total > 0 && data?.data?.map((item, index) => (
            <div className="text-sm border bg-white rounded-xl mb-1 p-4" key={index}>
              <div className="grid grid-cols-5">
                <div className="col-span-4">
                  <div className="flex gap-3 items-center">
                    <div className="bg-main text-white rounded-full p-2"> <LuNetwork /> </div>
                    <div className="">
                      <div className="font-bold text-lg">{item.title}</div>
                      <div className="text-sm font-medium">{item.service}</div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className={`text-green-600 font-bold text-right`}>
                    {NairaSign}
                    {item.amount}
                  </div>
                  <div className="flex items-center justify-end gap-1 text-sm"> <SlGlobe className="text-xs" /> {moment(item.createdAt).format('Do MMM YYYY')}</div>
                </div>
              </div>
              <div className="text-sm border-t">{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </UserLayout>
  );
}

import { dispatchUser } from 'app/reducer'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import UserLayout from 'components/user/UserLayout'
import BankOptions from 'components/utils/BankOptions'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RedeemData from './RedeemData'
import Selectinput from 'components/utils/Selectinput'
import { MenuItem } from '@mui/material'

export default function RedeemReward() {
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const { user } = useSelector(state => state.data)
  const [amountError, setAmountError] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [rewards, setRewards] = useState([])
  const [forms, setForms] = useState({
    bank: '',
    accname: '',
    accnumber: '',
    network: '',
    bundle: '',
    phone: '',
    amount: '',
  })

  const handleForms = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
  }
  const Options = [
    "Redeem to Data",
    "Redeem to Cash",
  ]

  useEffect(() => {
    (async () => {
      try {
        const response = await Authgeturl(Apis.mobilenig.all_datarewards)
        return setRewards(response.data)
      } catch (error) {
        ErrorAlert(`${error.message}`)
      }
    })()
  }, [])

  const handleActive1 = () => {
    if (!active1) {
      setActive1(true)
      setActive2(false)
    } else {
      setActive1(false)
    }
  }

  const handleActive2 = () => {
    if (!active2) {
      setActive2(true)
      setActive1(false)
    } else {
      setActive2(false)
    }
  }
  const handleAmountCheck = e => {
    const value = e.target.value
    if (value) {
      const item = rewards.find(ele => ele.amount === value)
      if (item.amount > user.coinbal) {
        return setAmountError(`Insufficient data balance to continue`)
      }
      setForms({...forms, amount: item.amount})
      return setAmountError('')
    }
  }

  const setup = (val) => {
    setForms({ ...forms, bank: val.bank_name })
  }

  const SubmitRequest = async e => {
    e.preventDefault()

    if (!forms.bank) return ErrorAlert('Select a valid bank')
    if (!forms.accname) return ErrorAlert('Account holder name is required')
    if (!forms.accnumber) return ErrorAlert('Account number is required')
    if (!forms.amount) return ErrorAlert('Amount to convert is required')
    if (amountError) return ErrorAlert(amountError)
    setLoading(true)
    try {
      const response = active1 ? null : await Authposturl(Apis.user.redeem_to_bank, forms)
      if (response.status !== 200) return ErrorAlert(`${response.msg}`)
      dispatch(dispatchUser(response.user))
      Swals('Request processing', response.msg, 'success')
      return navigate('/transactions')
    } catch (error) {
      ErrorAlert(`${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <UserLayout title="Redeem Reward">
      <div className="w-11/12 mx-auto mt-5 bg-white p-4 rounded-md">
        <div className="py-4"> <span className="text-sub">NOTE:</span> You can convert your GB reward to cash instantly into your bank account</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <div onClick={handleActive1}
              className="cursor-pointer flex items-center gap-3 bg-sub py-5 px-3 rounded-lg mb-5 text-white">
              <div className={`border-2 border-white rounded-full size-7 ${active1 ? 'bg-white' : ''}`}></div>
              {Options[0]}
            </div>
            {active1 && <>
              <RedeemData />
            </>}
          </div>

         {rewards.length > 0 && <div>
            <div onClick={handleActive2}
              className="cursor-pointer flex items-center gap-3 bg-sub py-5 px-3 rounded-lg text-white mb-5">
              <div className={`border-2 border-white rounded-full size-7 ${active2 ? 'bg-white' : ''}`}></div>
              {Options[1]}
            </div>
            {active2 && <>
              <div className="mb-3 relative">
                <div className="">--Select Bank--</div>
                <BankOptions title="Select Bank" setup={setup} />
              </div>
              <Forminput formName={'accname'} value={forms.accname} onChange={handleForms} content={'Account Name'} label="Account Name" />
              <Forminput formName={'accnumber'} value={forms.accnumber} onChange={handleForms} type="number" content={'Account Number'} lable="Account Number" />
              <Selectinput content="Data to convert" value={forms.amount} onChange={handleAmountCheck} formName={'amount'}>
                {rewards.map((item, index) => (
                  <MenuItem key={index} value={item.amount}>{item.bundle}MB</MenuItem>
                ))}
              </Selectinput>
              {amountError && <div className="text-sm text-red-700">{amountError}</div>}

            {!amountError && forms.amount &&  <div className="">Amount to receive: <span className="font-bold">{NairaSign}{forms.amount?.toLocaleString()}</span> </div>}
              <div className="">Current Reward balance: <span className="text-sub font-bold">{user.coinbal?.toLocaleString()}MB</span> </div>
              <div className=" mt-10">
                <Formbutton handleSubmission={SubmitRequest} loading={loading} title="Sumbit request" />
              </div>
            </>}
          </div>}
        </div>
      </div>
    </UserLayout>
  )
}

import axios from 'axios'
import Cookies from 'js-cookie'
import { tokenName } from './utils/functions'
import { io } from 'socket.io-client'


export let url, imgurl;
if (window.location.origin.includes('quickdirect.com.ng')) {
    url = `https://server.quickdirect.com.ng/`
    imgurl = `https://server.quickdirect.com.ng`
} 
if(window.location.origin.includes('quickdirect.netlify.app')) {
    url = `https://pwan.ic-bconline.com/`
    imgurl = `https://pwan.ic-bconline.com`
}
if(window.location.origin.includes('localhost')) {
    url = 'http://localhost:5003/'
    imgurl = 'http://localhost:5003'
}


export const SocketConnect = io(imgurl)
SocketConnect.on('connect', () => {
})


const user_urls = {
    create_account: 'user/create-account',
    accept_registration_code: `user/accept-registration-otp`,
    resend_otp: `user/resed-otp`,
    create_account_pin: `user/create-account-pin`,
    login_account: `user/login-account`,
    profile: `user/profile`,
    upload_profile_photo: `user/upload-profile-photo`,
    logout: `user/logout-account`,
    find_account_by_email: `user/find-account-by-email`,
    password_request: `user/password-request`,
    all: `user/all-users`,
    users: `user/users`,
    my_referrals: `user/all-referrals`,
    confirm_user_to_send_money: `user/confirm-user-to-send-money`,
    send_money: `user/send-money`,
    send_money_to_bank: `user/send-money-to-bank`,
    update_user_role: `user/update-user-role`,
    update_user_balance: `user/update-user-balance`,
    admin_update_user_info: `user/admin-update-user-info`,
    direct_deposit: `user/direct-deposit`,
    send_pin_verification: `user/send-pin-verification`,
    verify_and_update_pin: `user/verify-and-update-pin`,
    admin_dashboard: `user/admin-dashboard`,
    upload_kyc: `user/upload-kyc`,
    all_kyc_uploads: `user/all-kyc-uploads`,
    update_kyc_status: `user/update-kyc-status`,
    update_profile_details: `user/upload-profile-details`,
    change_activity_status: `user/change-acitivity-status`,
    manage_department: `user/manage-department`,
    all_departments: `user/all-departments`,
    all_admin_staffs: `user/all-admin-staffs`,
    assign_admin_staffs: `user/assign-admin-staffs`,
    upload_feedback_from_client: `user/upload-feedback-from-client`,
    update_chat_aval: `user/update-chat-aval`,
    all_processing_withdrawals: `user/all-unprocessed-withdrawals`,
    update_withdrawals: `user/update-withdrawals`,
    all_withdrawals: `user/all-withdrawals`,
    all_deposits: `user/all-deposits`,
    redeem_to_bank: `user/redeem-to-bank`,

    create_account_plan: `user/account-plan/create`,
    update_account_plan: `user/account-plan/update`,
    all_account_plan: `user/account-plan/all`,
    activate_account_plan: `user/account-plan/activate`,
    profile_account_plan: `user/account-plan/profile`,
    account_plan_transactions: `user/account-plan/transactions`,
}

const feed_urls = {
    create_feeds: `feeds/create-feed`,
    featured_news: `feeds/featured-news`,
    all_feeds: `feeds/all-feeds`,
    single_feed: `feeds/single-feed`,
    update_feed: `feeds/update-feed`,
    delete_feed: `feeds/delete-feed`,
    create_category: `feeds/create-category`,
    update_category: `feeds/update-category`,
    all_category: `feeds/all-category`,
    delete_category: `feeds/delete-category`,
    create_shortlet: `feeds/create-shortlet`,
    update_shortlet: `feeds/update-shortlet`,
    all_shortlet: `feeds/all-shortlet`,
    filter_shortlet: `feeds/filter-shortlet`,
    delete_shortlet: `feeds/delete-shortlet`,
    upload_shortlet_image: `feeds/upload-shortlet-image`,
    delete_shortlet_image: `feeds/delete-shortlet-image`,
    single_shortlet: `feeds/single-shortlet`,
    request_pickup: `feeds/request-pickup`,
    all_pickup_requests: `feeds/all-pickup-requests`,
    update_pickup_requests: `feeds/update-pickup-requests`,
    pickup_requests: `feeds/pickup-requests`,
    pickup_order: `feeds/pickup-order`,
    create_services: 'feeds/create-services',
    update_services: 'feeds/update-services',
    newsletter_subscription: 'feeds/newletter-subscription',
    start_chat: `feeds/start-chat`,
    send_chat_message: 'feeds/send-chat-message',
    active_chat: 'feeds/active-chat',
    all_rooms: 'feeds/all-rooms',
    room_messages: 'feeds/room-messages',
    submit_chat_feedback: 'feeds/submit-chat-feedback',
    end_chat_session: 'feeds/end-chat-session',
    agent_join_room: 'feeds/agent-join-room',
    my_connected_rooms: 'feeds/my-connected-rooms',
    manage_partners_logo: 'feeds/manage-partners-logo',
    all_partners_logo: 'feeds/all-partners-logo',
}

const investment_urls = {
    create_asset: `plan/asset/create-asset`,
    update_asset: `plan/asset/update-asset`,
    all_asset: `plan/asset/all-assets`,
    create_plan: `plan/create-plan`,
    update_plan: `plan/update-plan`,
    single_plan: `plan/single-plan`,
    all_plans: `plan/all-plans`,
    delete_plan: `plan/delete-plan`,
    add_stock: `plan/add-stock`,
    my_stock: `plan/my-stock`,
    stock: `plan/user/stock`,
    topup_stock_account: `plan/topup-stock-account`,
    deduct_stock_account: `plan/deduct-stock-account`,
    purchase_stock: `plan/purchase-stock`,
    generate_plan_invoice: `plan/generate-plan-invoice`,
    agree_to_investment: `plan/agree-to-investment`,
    purchase_investment: `plan/purchase-investment`,
    my_purchases: `plan/my-purchases`,
    autorenew: `plan/autorenew-stock`,
    sell_stock: `plan/sell-stock`,
    refill_stock: `plan/refill-stock`,
    open_withdraw_order: `plan/open-withdraw-order`,
    all_withdraw_orders: `plan/all-withdraw-orders`,
    update_withdraw_orders: `plan/update-withdraw-orders`,
    all_user_withdraw_orders: `plan/all-user-withdraw-orders`,
    investment_manager: `plan/investment-manager`,
    all_investment_manager: `plan/all-investment-manager`,
    reinvest: `plan/reinvest`,
}
const trans_urls = {
    my_transactions: `transactions/my-details`
}

const notify_url = {
    create_notify: `feeds/create-notification`,
    my_notifies: `feeds/my-notifications`,
    read_note: `feeds/read-notification`,
}


const ufitpay_urls = {
    all_services: `secure/all_services`,
    service_vendors: `secure/service_vendors`,
    service_packages: `secure/service_packages`,
    service_get_price: `secure/service_get_price`,
    initiate_service_purchase: `secure/initiate_service_purchase`,
    all_works: `secure/all-works`,
    service_price_lists: `secure/service_price_lists`,
    bank_codes: `secure/bank-codes`,
    verify_identity: `secure/verify-identity`,
    balance: `secure/balance`,
    deposit_to_stock: `secure/deposit-to-stock`,
    purchase_airtime: `secure/purchase-airtime`,
}

const serviceapi_urls = {
    all_services: `serviceapi/all_services`,
    service_details: `serviceapi/service_details`,
    add_service: `serviceapi/add-service`,
    update_service: `serviceapi/update-service`,
    delete_service: `serviceapi/delete-service`,
    view_services: `serviceapi/view-services`,
    display: `serviceapi/display`,
    validate_cable: `serviceapi/validate-cable`,
    validate_electricity: `serviceapi/validate-electricity`,
    purchase_airtime: `serviceapi/airtime`,
    purchase_data: `serviceapi/data`,
    purchase_electric: `serviceapi/electric`,
    purchase_cable: `serviceapi/cable`,
    purchase_airtime_to_cash: `serviceapi/a2c`,
    my_service_transactions: `serviceapi/my-service-transactions`,
    my_single_service_transaction: `serviceapi/my-service-transaction`,
    all_services_transaction: `serviceapi/all-services-transactions`,
    single_service_transaction: `serviceapi/single-services-transaction`,
    generate_invoice: `serviceapi/generate-invoice`,
    manage_airtime_cashback: `serviceapi/manage-airtime-cashback`,
    all_airtime_cashback: `serviceapi/all-airtime-cashback`,
}

const shares_urls = {
    create_share_class: 'shares/create-share-class',
    update_share_class: 'shares/update-share-class',
    delete_share_class: 'shares/delete-share-class',
    all_share_class: 'shares/all-share-class',
    manage_share_asset: 'shares/manage-share-asset',
    all_share_asset: 'shares/all-share-asset',
    request_shareholding: 'shares/request-shareholding',
    my_shares: 'shares/my-shares',
    all_shareholders: 'shares/all-shareholders',
    send_email_verification: 'shares/send-mail-verification',
    confirm_send_email_verification: 'shares/confirm-send-mail-verification',
    complete_shareholder_request: 'shares/complete-shareholder-request',
    all_shareholder_requests: 'shares/all-shareholder-requests',
    approve_shareholder_request: 'shares/approve-shareholder-request',
    allot_shareholder_grant: 'shares/allot-shareholder-grant',
    updated_alloted_shares: 'shares/update-alloted-shares',
    shares_login: 'shares/shares-login',
    shares_profiler: 'shares/shares-profiler',
    sell_off_shares: 'shares/sell-off-shares',
    save_details: 'shares/save-details',
    verify_shares_payment: 'shares/verify-shares-payment',
}

const ercas_urls = {
    initiate_account_deposit: `v1/initiate-payment`,
    initiate_client_account_deposit: `v1/initiate-client-payment`,
    verify_account_deposit: `v1/verify-payment`,
}


const mobilenig_urls = {
    create_service: `v2/create-service`,
    all_services: `v2/all-services`,
    generate_invoice: `v2/generate-service-invoice`,
    purchase_airtime: `v2/purchase-airtime`,
    purchase_data: `v2/purchase-data`,
    redeem_data: `v2/redeem-data`,
    all_transactions: `v2/all-service-transactions`,
    single_transaction: `v2/single-service-transaction`,
    check_data_pricing: `v2/check-pricing`,
    wallet_history: `v2/wallet-histories`,
    admin_all_transactions: `v2/get-all-service-transactions-admin`,
    open_cashback_withdrawal: `v2/cashback/open-withdrawal`,
    manage_cashback_withdrawals: `v2/cashback/manage-withdrawal`,
    all_cashback_withdrawals: `v2/cashback/all-withdrawals`,
    all_cashback_transactions: `v2/cashback/all-transactions`,
    all_datarewards: `v2/datareward/all`,
    manage_datarewards: `v2/datareward/manage`,
}


export const Apis = {
    user: user_urls,
    feeds: feed_urls,
    investments: investment_urls,
    notify: notify_url,
    transactions: trans_urls,
    secure: ufitpay_urls,
    serviceapi: serviceapi_urls,
    shares: shares_urls,
    ercas: ercas_urls,
    mobilenig: mobilenig_urls,
}

export const Posturl = async (endpoint, data) => {
    const res = await axios.post(`${url}${endpoint}`, data)
    return res.data
}

export const geturl = async (endpoint) => {
    const res = await axios.get(`${url}${endpoint}`)
    return res.data
}

// authenticated routes
export const Authposturl = async (endpoint, data) => {
    const res = await axios.post(`${url}${endpoint}`, data, {
        headers: {
            Authorization: `Bearer ${Cookies.get(tokenName)}`
        }
    })
    return res.data
}

export const Authputurl = async (endpoint, data) => {
    const res = await axios.put(`${url}${endpoint}`, data, {
        headers: {
            Authorization: `Bearer ${Cookies.get(tokenName)}`
        }
    })
    return res.data
}

export const Authgeturl = async (endpoint) => {
    const res = await axios.get(`${url}${endpoint}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get(tokenName)}`
        }
    })
    return res.data
}



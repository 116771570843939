import UserLayout from 'components/user/UserLayout'
import { SlClock, SlExclamation } from 'react-icons/sl'
import { Link } from 'react-router-dom'
import { Apis, Authgeturl } from 'components/Apis'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import Imaged from 'components/utils/Imaged'

const Feeds = () => {
    const { data, isLoading } = useQuery({
        queryKey: [`user-dash-feeds`],
        queryFn: async () => {
            const res = await Authgeturl(Apis.feeds.all_feeds)
            if (res.status === 200) {
                return res
            }
        }
    })

    if (isLoading) {
        return (
            <UserLayout title="feeds">
                <div className="w-11/12 mx-auto dark:text-slate-100">
                    <div className="capitalize text-xl font-medium mb-5">latest newsfeeds</div>
                    <div className="">
                        {new Array(5).fill().map((item, i) => (
                            <div className="bg-slate-200 dark:bg-slate-600 h-[20rem] mb-3" key={i}>
                                <div className="p-3">
                                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem] w-3/5"></div>
                                </div>
                                <div className="bg-slate-300 dark:bg-slate-700 h-[10rem]"></div>
                                <div className="p-3">
                                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem]"></div>
                                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem] w-3/5 ml-auto mt-3"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </UserLayout>
        )
    }
    return (
        <UserLayout title="feeds">
            <div className="w-11/12 mx-auto dark:text-slate-100">
                <div className="capitalize text-xl font-medium mb-5">latest newsfeeds</div>
                <div className="">
                    {data.total < 1 && <div className="text-center text-zinc-500 flex flex-col items-center gap-5 mt-10">
                        <SlExclamation className='text-5xl' />
                        <div className="">There are no available stocks at the moment</div>
                    </div>}
                    {data?.data?.map((item, i) => (
                        <div className="border mb-3 shadow-lg dark:bg-slate-600 rounded-lg p-3" key={i}>
                            <Link to={`/feeds/${item.slug}/${item.id}`} className="text-blue-600 dark:text-blue-300 text-sm mb-2">{item.title.slice(0, 80)}, read more...</Link>
                            <div className="mt-4"> {item.image ? <Imaged src={item.image} alt="" className="w-full max-h-[10rem] object-cover" /> : ''}</div>
                            <div className='text-sm py-3' dangerouslySetInnerHTML={{ __html: item.content.slice(0, 200) }} />
                            <div className="grid grid-cols-2">
                                <div className="text-sm capitalize"></div>
                                <div className="text-xs text-right flex items-center gap-2 justify-end"> <SlClock /> {moment(item.createdAt).format('DD-MM-YYYY h:mA')} </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </UserLayout>
    )
}

export default Feeds
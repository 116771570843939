import React, { useState } from "react";
import avatar from "assets/images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { FaCameraRetro } from "react-icons/fa6";
import { ErrorAlert, Errors, SuccessAlert } from "components/utils/functions";
import { Apis, Authposturl, Authputurl, imgurl } from "components/Apis";
import { dispatchUser } from "app/reducer";
import Forminput from "components/utils/Forminput";
import Imaged from "./Imaged";
import CopyToClipboard from "react-copy-to-clipboard";
import { SlDocs } from "react-icons/sl";
import { BsCopy } from "react-icons/bs";

const ProfileComponent = () => {
  const { user } = useSelector((state) => state.data);
  const [image, setImage] = useState(
    user.image ? `${imgurl}/profile/${user.image}` : null
  );
  const [, setloading] = useState(false);
  const dispatch = useDispatch();
  const [forms, setForms] = useState({
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    middlename: user?.middlename || "",
    dailcode: user?.dailcode || "+234",
    phone: user?.phone?.replace(user.dailcode, "") || "",
  });

  const handleForms = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  const handleAccountUpdating = async (e) => {
    e.preventDefault();
    if (!forms.firstname) return ErrorAlert("First name is required");
    if (!forms.lastname) return ErrorAlert("Last name is required");
    if (!forms.dailcode) return ErrorAlert("Country Dial code is required");
    if (!forms.phone) return ErrorAlert("Phone Number is required");
    setloading(true);
    try {
      const response = await Authputurl(
        Apis.user.update_profile_details,
        forms
      );
      if (response.status === 200) {
        SuccessAlert(response.msg);
        return dispatch(dispatchUser(response.details));
      }
    } catch (error) {
      Errors(`${error.message}`);
    } finally {
      setloading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      const formdata = new FormData();
      formdata.append("image", file);
      try {
        const response = await Authposturl(
          Apis.user.upload_profile_photo,
          formdata
        );
        if (response.status === 200) {
          SuccessAlert(response.msg);
          return dispatch(dispatchUser(response.details));
        }
      } catch (error) {
        Errors(`${error.message}`);
      }
    }
  };

  return (
    <div>
      <div className="">
        <div className="w-fit h-fit mx-auto">
          <label className="cursor-pointer">
            <div className="w-fit mx-auto relative">
              {/* {image ? <Imaged src={image ? image : avatar} alt="" className="w-32 h-32 rounded-full" /> : <Imaged src={`${imgurl}/profile/${user.image}`} alt="" className="w-32 h-32 rounded-full" />} */}
              <Imaged
                src={image ? image : avatar}
                alt=""
                className="w-32 h-32 rounded-full object-cover"
              />
              <div className="absolute bottom-0 right-0">
                <div className="bg-white p-2 rounded-full shadow-2xl border">
                  <FaCameraRetro />
                  <input type="file" onChange={handleFileUpload} hidden />
                </div>
              </div>
            </div>
          </label>
        </div>
        <div className="text-center font-bold">
          {user.firstname} {user.middlename} {user.lastname}
        </div>
        <div className="text-center">{user.email}</div>
        <div className="text-center">{user.phone}</div>
        <div className="w-fit mx-auto">
          <CopyToClipboard
            text={user.refid}
            onCopy={() => SuccessAlert("Referral code copied")}
          >
            <div className="flex cursor-pointer items-center gap-2">
              <span>Referral ID: {user.refid}</span> <BsCopy />
            </div>
          </CopyToClipboard>
        </div>
        <form
          className="mt-10 w-11/12 mx-auto max-w-[50rem]"
          onSubmit={handleAccountUpdating}
        >
          <div className="mb-3">
            <div className="">First Name</div>
            <Forminput
              readOnly
              formName={"firstname"}
              value={forms.firstname}
              onChange={handleForms}
            />
          </div>
          <div className="mb-3">
            <div className="">Middle Name (Optional)</div>
            <Forminput
              readOnly
              formName={"middlename"}
              value={forms.middlename}
              onChange={handleForms}
            />
          </div>
          <div className="mb-3">
            <div className="">Last Name</div>
            <Forminput
              readOnly
              formName={"lastname"}
              value={forms.lastname}
              onChange={handleForms}
            />
          </div>
          <div className="grid grid-cols-5 gap-3">
            {/* <div className="col-span-1">
              <DailOptions
                defaultValue={forms.dailcode}
                setup={(val) => setForms({ ...forms, dailcode: val.dial_code })}
              />
            </div> */}
            <div className="col-span-4">
              {/* <Forminput title='number' value={forms.phone} formName="phone" onChange={handleForms} /> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileComponent;

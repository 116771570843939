import React from 'react'
import img from 'assets/main-images/404.svg'
import Imaged from 'components/utils/Imaged'
import { Link } from 'react-router-dom'


export default function Notfound() {

    return (
    <div className='h-screen flex flex-col items-center justify-center w-11/12 mx-auto'>
        <Imaged src={img} alt="" className="w-full h-[50dvh]" />
        <div className="font-bold text-4xl lg:text-5xl text-center">Page not found</div>
        <div className="text-center">The page you are looking for <span className="text-red-600 font-bold">{window.location.href}</span> does not exists</div>
        <div className="mt-10">
            <Link className='bg-main text-white py-3 px-6 rounded-lg' to="/">Back to home</Link>
        </div>
    </div>
  )
}


import React from 'react'

const Loading = () => {
    return (
        <div className="absolute top-0 left-0 bg-white/70 w-full h-full flex items-center z-70 justify-center">
            <div className="loader"></div>
        </div>
    )
}

export default Loading
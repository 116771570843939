
import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import SelectOptions from 'components/utils/SelectOptions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert, Swals, generateRandomNumbersAndAlphabets } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useLocation, useNavigate } from 'react-router-dom'
import ConfirmationModal from 'components/utils/ConfirmationModal'
import { SlCheck } from 'react-icons/sl'
import { dispatchUser } from 'app/reducer'
import { useDispatch, useSelector } from 'react-redux'
import ServiceConfirmation from './ServiceConfirmation'

const Cable = () => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const tag = pathname.split('/')[1]
    const {user} = useSelector(state => state.data)
    const [plans, setPlans] = useState([])
    const [proceed, setProceed] = useState({
        status: false,
        message: ''
    })
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [single, setSingle] = useState({
        status: false,
        cashback: 0,
        amount: 0,
        paymethod: '',
        service: '',
        data: {},
        product: '',
        amountToPay: 0,
    })
    const [data, setData] = useState({})
    const [forms, setForms] = useState({
        Service: "",
        Serviceid: "",
        Plan: "",
        Number: "",
    })
    
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    
    const handleService = e => {
        const val = e.target.value
        const item = data.tag.plans.find(ele => ele.serviceid === val)
        const allplans = data.services.filter(ele => ele.network === item.servicetype)
        setPlans(allplans)
        setForms({
            ...forms,
            Serviceid: val,
            Service: item.service
        })
    }
    useEffect(() => {
        const fetchService = async () => {
            try {
                const response = await Authgeturl(`${Apis.serviceapi.display}/${tag}`)
                if (response.status === 200) {
                    return setData(response.msg)
                }

            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                //
            }
        }
        fetchService()
    }, [])

    const handlePlans = e => {
        const val = e.target.value
        const item = plans.find(ele => ele.planID === val)
        const finals = parseFloat((data.tag?.charges * parseFloat(item.price)) / 100)
        const cfinals = parseFloat((data.tag?.cashback * parseFloat(item.price)) / 100)
        setForms({
            ...forms,
            Plan: val
        })
        setSingle({
            ...single,
            cashback: cfinals,
            data: item,
            amount: finals + parseFloat(item.price),
            amountToPay: finals + parseFloat(item.price),
        })
    }

    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.Service) return ErrorAlert('Select your preferred network')
        if (!forms.Plan) return ErrorAlert('Select a Bundle to purchase')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')
        setSingle({
            ...single,
            serv: forms.Service,
            pplan: forms.Plan,
            Number: forms.Number,
            servid: forms.Serviceid
        })
        if (!proceed.status) {
            setLoading(true)
            try {
                const response = await Authgeturl(`${Apis.serviceapi.validate_cable}?Service=${forms.Serviceid}&Meter=${forms.Number}`)
                if (response.msg.status !== "Failed") {
                    setProceed({
                        status: true,
                        message: response.msg.name
                    })
                } else {
                    ErrorAlert(`${response.msg.response}`)
                }
            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                setLoading(false)
            }
        } else {
            // const title = ` Kindly confirm your request to purchase ${view.service} ${view.data?.package} at -${NairaSign}${view.amount?.toLocaleString()}`
            setSingle({
                ...single,
                status: true,
                product: `${single.data?.network} ${single.data?.package}`
            })
        }
    }
    const submitRequest = async (values) => {
        const formbody = {
            ...values
        }
        setLoader(true)
        try {
            const response = await Authposturl(Apis.serviceapi.generate_invoice, formbody)
            if (response.status === 200) {
                const invoicebody = {
                    invoiceid: response.msg
                }
                const result = await Authposturl(Apis.serviceapi.purchase_cable, invoicebody)
                if (result.status === 200) {
                    dispatch(dispatchUser(result.user))
                    Swals('Successful purchase', result.msg, 'success')
                    return navigate('/service_transactions')
                } else {
                    Swals('Error Purchasing Cable', `${result.msg}`, 'error')
                }
            } else {
                Swals('Error Purchasing Cable', `${response.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }
    const DirectServicePurchase = async (invoiceid) => {
        setLoader(true)
        try {
            const invoicebody = {
                invoiceid
            }
            const result = await Authposturl(Apis.serviceapi.purchase_cable, invoicebody)
            if (result.status === 200) {
                dispatch(dispatchUser(result.user))
                Swals('Successful purchase', result.msg, 'success')
                return navigate('/service_transactions')
            } else {
                Swals('Error Purchasing Cable', `${result.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }
    return (
        <UserLayout title="Cable Purchase">
        {single.status && <ServiceConfirmation
            loading={loader}
            setLoading={setLoader}
            titletag="cable"
            single={single}
            setSingle={setSingle}
            user={user}
            handleSubmission={submitRequest}
            DirectServicePurchase={DirectServicePurchase}
            closeView={() => setSingle({ ...single, status: false })}
        />}
            <div className="w-11/12 mx-auto">
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <Forminput value={forms.Serviceid} formName={'Serviceid'} onChange={handleService} formtype='select'>
                                {data.tag?.plans?.map((ele, index) => (
                                    <option disabled={ele.aval === 'true' ? false : true} value={ele.serviceid} key={index}>{ele.servicetype}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="relative mb-4">
                            <div className="">Select Bundle</div>
                            <Forminput value={forms.Plan} formName={'Plan'} onChange={handlePlans} formtype='select'>
                                {plans?.map((ele, index) => (
                                    <option value={ele.planID} key={index}>{ele.package} = {NairaSign}{parseFloat((data.tag?.charges * parseFloat(ele.price)) / 100 + parseFloat(ele.price))?.toLocaleString()}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="mb-4">
                            <div className="">Decoder Number</div>
                            <Forminput formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        {proceed.status && <div className="mb-4">
                            <div className="">Verified Meter Holder</div>
                            <div className="flex items-center gap-3 p-3 border rounded-md border-slate-300 bg-green-300/20"> <SlCheck className='text-xl text-green-500' /> {proceed.message}</div>
                        </div>}
                        {!proceed.status ? <Formbutton title="proceed" loading={loading} /> : <Formbutton title="continue" loading={loading} />}
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}

export default Cable

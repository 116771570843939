import img6 from 'assets/main-images/sr.svg'
import img7 from 'assets/main-images/dp.svg'
import img8 from 'assets/main-images/tw.svg'
import img9 from 'assets/main-images/cl.svg'
import img10 from 'assets/main-images/mtr.svg'
import img11 from 'assets/main-images/qsd.svg'
import img12 from 'assets/main-images/airtime.svg'
import img13 from 'assets/main-images/buy_data.png'
import img14 from 'assets/main-images/elec.png'
import img15 from 'assets/main-images/cable.jpg'
import img16 from 'assets/main-images/lets.png'
import img17 from 'assets/main-images/airtime.png'
import img18 from 'assets/main-images/pin.png'
import img19 from 'assets/main-images/th.png'
import img20 from 'assets/main-images/crp.png'
import img21 from 'assets/main-images/ots.png'
import works from 'assets/images/works.webp'
import calc from 'assets/images/calculator.webp'
import model from 'assets/images/model.webp'
import returns from 'assets/images/returns.svg'
import compound from 'assets/images/compound.svg'
import safety from 'assets/images/safety.svg'
import dividends from 'assets/images/dividends.svg'
import max from 'assets/images/max.svg'
import lints from 'assets/main-images/lints.png'
import lil1 from 'assets/images/lil1.jpg'
import lil3 from 'assets/images/lil3.png'
import lil4 from 'assets/images/lil4.jpg'
import { siteNameShort } from './functions'
import { MdFolderOpen, MdPeopleAlt } from 'react-icons/md'
import { BiBarChartAlt, BiCar } from 'react-icons/bi'
import { SlBell, SlCallIn, SlFolderAlt, SlHome } from 'react-icons/sl'
import { FaChartLine } from 'react-icons/fa'
import img22 from 'assets/main-images/MTN.png'
import img23 from 'assets/main-images/Airtel.png'
import img24 from 'assets/main-images/Glo.png'
import img25 from 'assets/main-images/9mobile.png'
import img26 from 'assets/main-images/fund.png'
import img27 from 'assets/main-images/redeem.png'
import img28 from 'assets/main-images/transfer.png'
import img29 from 'assets/main-images/b1.png'
import img30 from 'assets/main-images/b2.png'
import img31 from 'assets/main-images/b3.png'
import img32 from 'assets/main-images/b4.png'
import img33 from 'assets/main-images/b5.png'
import img34 from 'assets/main-images/b6.png'


export const ServiceImages = [
    {tag: 'mtn', image: img22},
    {tag: 'airtel', image: img23},
    {tag: 'glo', image: img24},
    {tag: '9mobile', image: img25},
]

export const BillsServices = [
    {
        title: `Instant Airtime to Cash`,
        content: `Convert your airtime to cash in seconds using the new and improved Airtime to cash service.`,
        image: img31
    },
    {
        title: `Airtime Topup`,
        content: `Purchase airtime for MTN, Glo, 9mobile & Airtel at the best possible / discounted rates.`,
        image: img33
    },
    {
        title: `Data Purchase`,
        content: `We offer the best rates on data purchase for all available networks in Nigeria.`,
        image: img30
    },
    {
        title: `Bills Payments`,
        content: `Pay for your Tv/Cable (Dstv, Gotv, etc.), Internet, Electricity & other merchants using the Quick Direct app.`,
        image: img29
    },
    {
        title: `Airtime Payments`,
        content: `Send and receive payments, pay bills & Receive & send cash using Quick Direct.`,
        image: img34
    },
    {
        title: `Earn & Redeem Data to Cash`,
        content: `Every single purchase earn you Data bundle, which are redeemable to cash into your bank account instantly .`,
        image: img32
    },
]

export const UserDashboardMajors = [
    {title: 'Add money', link: '/fund-account', image: img26 },
    {title: 'Redeem GB reward', link: '/redeem-reward', image: img27 },
    {title: 'Send money', link: '/send-money', image: img28 },
]

export const AdminDashboard = [
    { title: "users", tag: 'users', Icon: MdPeopleAlt, bg: 'bg-green-400', url: `` },
    { title: "service transactions", tag: 'servicetrans', Icon: BiBarChartAlt, bg: 'bg-blue-400', url: `` },
    { title: "services", tag: 'services', Icon: SlFolderAlt, bg: 'bg-purple-400', url: `` },
    { title: "total investmented", tag: 'investments', Icon: FaChartLine, bg: 'bg-zinc-400', url: `` },
    { title: "shortlets", tag: 'shortlets', Icon: SlHome, bg: 'bg-sub', url: `` },
    { title: "pickups", tag: 'pickups', Icon: BiCar, bg: 'bg-teal-400', url: `` },
    { title: "shares requests", tag: 'sharesrequests', Icon: SlCallIn, bg: 'bg-red-400', url: `` },
    { title: "investment plans", tag: 'plans', Icon: MdFolderOpen, bg: 'bg-green-400', url: `` },
    { title: "newsletters", tag: 'newsletters', Icon: SlBell, bg: 'bg-sub', url: `` },
]

export const DriveList = [
    {
        img: img7,
        title: `Dependability`,
        content: `(Dependable service): We pride ourselves on being your dependable financial institution`
    },
    {
        img: img6,
        title: `Social responsibility`,
        content: `One of our key focuses is to be socially responsible and give back to our community.`
    },
    {
        img: img9,
        title: `Client-focused`,
        content: `Customer satisfaction is at the heart of our service delivery.`
    },
    {
        img: img11,
        title: `Quality service delivery`,
        content: `We continually go above and beyond in delivering impeccable services`
    },
    {
        img: img10,
        title: `Mutual Respect`,
        content: `We want to ensure a mutually respectful relationship with our clients.`
    },
    {
        img: img8,
        title: `Teamwork`,
        content: `We work as a team to deliver value to clients and stakeholders.`
    },
]

export const PwanPlan = [
    {
        img: max,
        title: `${siteNameShort} istock fund`,
        content: `Grow your money with our safe and flexible investment product. You can withdraw at any time you need your money.`
    },
    {
        img: max,
        title: `${siteNameShort} midas fund`,
        content: `Grow your money with our safe and flexible investment product. You can withdraw at any time you need your money.`
    },
    {},
    {
        img: max,
        title: `${siteNameShort} prime fund`,
        content: `Grow your money with our safe and flexible investment product. You can withdraw at any time you need your money.`
    },
]

export const WhyPwan = [
    {
        img: safety,
        color: 'bg-purple-200/60',
        title: 'safety first',
        content: 'Fund safety is our most important principle.'
    },
    {
        img: returns,
        color: 'bg-orange-200/60',
        title: 'good returns',
        content: 'We achieve industry-leading returns for our customers.'
    },
    {
        img: dividends,
        color: 'bg-teal-200/60',
        title: 'daily dividends',
        content: 'You accrue dividends and gains on a daily basis.'
    },
    {
        img: compound,
        color: 'bg-red-200/60',
        title: 'compound gains',
        content: 'Our compound interest model increases your return.'
    }
];


export const AllServiceLinks = [
    {
        title: 'airtime',
        img: img12,
        url: '/airtime',
    },
    {
        title: 'data',
        img: img13,
        url: '/data'
    },
    {
        title: 'electricity',
        img: img14,
        url: '/electricity'
    },
    {
        title: 'cable',
        img: img15,
        url: '/cable'
    },
    {
        title: 'exam pin',
        img: img18,
        url: '/exam_pin'
    },
    {
        title: 'a2c',
        img: img17,
        url: '/airtime_to_cash'
    },
    {
        title: 'service transactions',
        img: lints,
        url: '/service_transactions'
    },
    {
        title: 'shortlets',
        img: img16,
        url: '/dashboard/shortlets'
    },
    {
        title: 'travels & hotels',
        img: img19,
        url: false
    },
    {
        title: 'buy/sell crypto',
        img: img20,
        url: false
    },
    {
        title: 'global transfer',
        img: img21,
        url: false
    },
]

export const HomeAboutUs = [
    {
        img: works,
        title: 'How it Works',
        content: 'We help you grow your money and distribute daily compound interest to all our customers.'
    },
    {
        img: calc,
        title: 'Our Model',
        content: 'We carefully and diligently use our expertise to grow your money using safe, high return investments.'
    },
    {
        img: model,
        title: 'Investment Calculator',
        content: 'Our investment calculator simulates the daily growth of your money.'
    },
]

export const HomeNavs = [
    {
        title: 'Home',
        url: '/'
    },
    // {
    //     title: 'plans',
    //     url: ''
    // },
    {
        title: 'Shortlets',
        url: '/shortlets'
    },
    {
        title: 'Feeds',
        url: '/newsfeeds'
    },
    {
        title: 'Own a share',
        url: '/partnership'
    },
]

export const HeadStaffs = [
    // {
    //     img: lil,
    //     name: 'Lordwilliams Greatness',
    //     title: 'CEO / founder',
    //     link: '/williams'
    // },
    {
        img: lil1,
        name: 'Austine R N Okonkwo',
        title: 'Co. founder',
        link: ''
    },
    // {
    //     img: whoweare,
    //     name: 'james author',
    //     title: 'Chief financial officer'
    // },
    {
        img: lil3,
        name: 'ABDUL RAHEEM',
        title: 'president',
        link: ""
    },
    {
        img: lil4,
        name: 'Rachel Abala Pepple',
        title: 'GMO/MD',
        link: ""
    },
    // {
    //     img: whoweare,
    //     name: 'james author',
    //     title: 'vice president'
    // },
]

export const FaqData = [
    {
        q: `What is Quick Direct?`,
        a: `Quick Direct is a financial services firm offering innovative investment solutions, Global Money Transfer, Travel & hotel booking, apartment rentals and more to individuals, institutions, and families.`
    },

    {
        q: `What services does Quick Direct provide ?`,
        a: `Our services include investment management, financial planning, wealth management, and advisory services tailored to each client's unique needs.`
    },

    {
        q: `What is the minimum investment required ?`,
        a: `The minimum investment varies depending on the specific service or portfolio.Please contact us for more information.`
    },

    {
        q: `How do I open an account with Quick Direct ?`,
        a: `You can open an account by allocating the[Get started bottom] in the home page.`
    },

    {
        q: `What is the investment process like ?`,
        a: `Our investment process involves understanding your goals, risk tolerance, and preferences, followed by creating a customized portfolio, ongoing monitoring, and regular updates.`
    },

    {
        q: `How do I access my account information ?`,
        a: `You can access your account information through our secure online portal or by contacting our client services team.`
    },

    {
        q: `What is Quick Direct's investment philosophy?`,
        a: `Our investment philosophy is centered around a long - term approach, diversification, and a commitment to excellence.`
    },

    {
        q: `How do I contact Quick Direct ?`,
        a: `You can contact us through our website, phone, email, or by visiting our office.`
    },

    {
        q: `Is my personal and financial information secure ?`,
        a: `Yes, we prioritize the security and confidentiality of your information, using robust measures to protect your data.`
    },

    {
        q: `What if I have a complaint or concern ?`,
        a: `We value your feedback and concerns.Please contact our client services team, and we will address your issue promptly and fairly.`
    },

    {
        q: `How do I stay updated on market insights and news ?`,
        a: `We publish regular market updates, insights, and news on our website and social media channels.You can also subscribe to our newsletter or follow us on social media.`
    }
]
import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowRight } from 'react-icons/fa'
import KycWarning from './KycStages/KycWarning'
import { ErrorAlert, Errors, NairaSign } from 'components/utils/functions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authposturl } from 'components/Apis'
import { useNavigate } from 'react-router-dom'
import Loading from 'components/utils/Loading'
import tops from 'assets/main-images/tops.png'
import Imaged from 'components/utils/Imaged'


const FundAccount = () => {
    const { user } = useSelector(state => state.data)
    const [open, setOpen] = useState(false)
    const [amount, setAmount] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const ValidateForm = e => {
        e.preventDefault()
        if (!amount) return ErrorAlert(`Enter a valid amount to topup`)
        if (parseFloat(amount) > user.limits) {
            return ErrorAlert('You cannot topup your account above your limit')
        }
        if (parseFloat(amount) < 1) {
            return ErrorAlert(`amount must be between ${NairaSign}1 and ${NairaSign}${user.limits.toLocaleString()}`)
        }
        Paynow()
    }

    const Paynow = async () => {
        const url = window.location.origin
        const formdata = {
            url: `${url}/fund-account/verify`,
            amount: parseFloat(amount),
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.ercas.initiate_account_deposit, formdata)
            if (response.msg.requestSuccessful) {
                window.location = response.msg.responseBody.checkoutUrl
            } else {
                setLoading(false)
                ErrorAlert(`${response.msg.responseMessage}`)
            }
        } catch (error) {
            setLoading(false)
            Errors(`${error.message}`)
        }
    }

    return (
        <UserLayout title="Topup my Account">
            {loading && <Loading />}
            <div className="w-11/12 mx-auto">
                {open && <KycWarning closeView={() => setOpen(false)} />}
                {user.kycstatus !== 'approved' && <div className="">
                    <div className="font-bold mb-5 text-2xl">Account Topup</div>
                    <div className="text-sm">Your account is running on a {NairaSign}{user.limits?.toLocaleString()} limit</div>
                    {user.kycstatus === 'pending' && <div className="text-sm">Submit your KYC informations to upgrade your account limit</div>}
                </div>}
                <div className="my-10">
                    <div className="bg-white p-3 rounded-lg">
                        <div className="font-bold text-xl mb-10 p-3 bg-slate-100 rounded flex items-center gap-2">
                            <div className="w-fit ">
                                <Imaged src={tops} alt="" className="w-auto h-16" />
                            </div>
                            Topup my Account</div>
                        <form onSubmit={ValidateForm}>
                            <div className="mb-3">
                                <div className="text-sm mb-2">Amount to pay ({NairaSign})</div>
                                <Forminput value={amount} onChange={e => setAmount(e.target.value)} label="0.00" type="number" />
                            </div>
                            <div className="w-fit ml-auto">
                                <Formbutton title="Pay now" />
                            </div>
                        </form>
                    </div>
                </div>

                {user.account?.verified === 'false' && <div className="bg-white  mx-auto p-2 rounded-lg">
                    <div className="bgkyc p-2">
                        <div className="font-bold text-xl mb-4">Identity not verified</div>
                        <div className="w-3/5">Kindly verify your account identity to activate operations and uplift your account limits</div>
                    </div>
                    <div
                        onClick={() => setOpen(!open)}
                        className="flex items-center pb-4 pr-3 justify-end gap-3 capitalize font-bold cursor-pointer">verify now <FaArrowRight /> </div>
                </div>}

            </div>
        </UserLayout>
    )
}

export default FundAccount
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const AHeader = ({title, url, total, link, tag="", tagtitle="", handleClick}) => {
  return (
    <div className='mx-auto rounded-lg shadow-xl bg-slate-800'>
        <div className="grid grid-cols-2 p-5">
            <div className="">
                <div className="text-base ize text-slate-200">{title}</div>
                <div className="text-xl text-green-300">{`${total < 10 ? `0${total}` : total}` || 0}</div>
            </div>
           {url !== false && <div className="ml-auto w-fit">
                <Link to={`${url}`} className="flex items-center gap-2 px-4 py-3 text-sm capitalize bg-white rounded-full w-fit"> <FaPlus /> {link}</Link>
            </div>}
           {tag === 'button' && <div className="ml-auto w-fit">
                <button 
                onClick={handleClick}
                className="flex items-center gap-2 px-4 py-3 text-sm capitalize bg-white rounded-full w-fit"> <FaPlus /> {tagtitle}</button>
            </div>}
        </div>
    </div>
  )
}

export default AHeader
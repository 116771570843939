import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { Apis, Authgeturl, imgurl } from 'components/Apis'
import { Errors, NairaSign } from 'components/utils/functions'
import { Link } from 'react-router-dom'
import { SlExclamation, SlMap } from 'react-icons/sl'
import { FaMapMarkerAlt } from 'react-icons/fa'
import LoadImage from 'components/utils/LoadImage'
import MobileFilter from '../../general/HomeShortlets/ShortletFilter/MobileFilter'
import ShortletFilter from '../../general/HomeShortlets/ShortletFilter/ShortletFilter'
import { MdOutlineFilterList } from 'react-icons/md'
import SingleShortletData from '../../general/HomeShortlets/SingleShortletData'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

const Shortlets = () => {
    const [items, setItems] = useState([])
    const [mobilefilter, setMobilefilter] = useState(false)
    const { user } = useSelector(state => state.data)
    const [stats, setStats] = useState({
        minprice: 0,
        maxprice: 1
    })
    const [view, setView] = useState({
        img: null,
        status: false
    })


    const { data, isLoading, isError } = useQuery({
        queryKey: [`user-shortlets-${user.id}`],
        queryFn: async () => {
            const response = await Authgeturl(Apis.feeds.all_shortlet)
            if (response.status === 200) {
                const findMax = response.msg.reduce((max, product) => (product?.price > max ? product?.price : max), response.msg[0]?.price);
                const findMin = response.msg.reduce((min, product) => (product?.price < min ? product?.price : min), response.msg[0]?.price);
                // maxsortPrice.current = parseInt(findMax)
                // minsortPrice.current = parseInt(findMin)
                setStats({
                    ...stats,
                    minprice: parseInt(findMin),
                    maxprice: parseInt(findMax)
                })
                setItems(response.msg)
                return response.msg
            }
        }
    })

    const Signal = values => {
        setItems(values)
    }

    if (!isLoading && data?.length < 1) return (
        <UserLayout title="Shortlets">
            <div className="">
                <div className="w-11/12 mx-auto lg:w-10/12 mb-20 mt-10">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-5">
                        <div className="hidden lg:block lg:col-span-2">
                            <div className="h-[35rem] bg-slate-200"></div>
                        </div>
                        <div className="lg:col-span-5">
                            <div className="">
                                <div className='flex flex-col items-center gap-4 my-32'>
                                    <SlExclamation className='text-5xl text-zinc-400' />
                                    <div className="text-center text-zinc-500">No Shortlets uploaded at the moment</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )

    if (isLoading) {
        return (
            <UserLayout title="Shortlets">
                <div className="">
                    <div className="w-11/12 mx-auto mb-20">
                        <div className="grid grid-cols-1 lg:grid-cols-7 gap-5">
                            <div className="hidden lg:block lg:col-span-2">
                                <div className="animate-pulse h-[40rem] bg-slate-200"></div>
                            </div>
                            <div className="lg:col-span-5">
                                <div className="">
                                    <div className="bg-slate-200 h-10 w-32 mb-4 animate-pulse"></div>
                                    <div className="grid grid-cols-1 gap-4">
                                        {new Array(5).fill(0).map((item, i) => (
                                            <div className="h-[10rem] bg-slate-200 animate-pulse" key={i}></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UserLayout>
        )
    }
    
    if (items && items.length > 0) return (
        <UserLayout title="Shortlets">
            <div className="">
                {mobilefilter && <MobileFilter
                    stats={stats}
                    border={true}
                    Signal={Signal}
                    shortlets={data}
                    closeView={() => setMobilefilter(!mobilefilter)}
                />}
                <div className="w-11/12 mx-auto mb-20">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-5">
                        <div className="hidden lg:block lg:col-span-2">
                            <ShortletFilter
                                border={true}
                                shortlets={data}
                                stats={stats}
                                Signal={Signal}
                            />
                        </div>
                        <div className="lg:col-span-5">
                            <div className="">
                                <div
                                    onClick={() => setMobilefilter(!mobilefilter)}
                                    className="flex lg:hidden items-center gap-2 mb-5 w-fit cursor-pointer">
                                    <MdOutlineFilterList className='text-2xl' />
                                    <div className="">Filter</div>
                                </div>
                                {items.length > 0 && <div className="">
                                    {items.map((item, index) => (
                                        <SingleShortletData
                                            key={index}
                                            url={`/dashboard/shortlets`}
                                            item={item}
                                        />
                                    ))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}

export default Shortlets

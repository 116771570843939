
import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import { AirtimeServices, ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { dispatchUser } from 'app/reducer'
import ServiceConfirmation from './ServiceConfirmation'
import { useQuery } from '@tanstack/react-query'
import { ServiceImages } from 'components/utils/PageUtils'


export default function Airtime() {
    const [loading,] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.data)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [items, setItems] = useState([])
    const [topups, setTopups] = useState({})
    const [forms, setForms] = useState({
        Service: "",
        Amount: "",
        Number: "",
        actual: "",
    })
    const [single, setSingle] = useState({
        status: false,
        amount: 0,
        actual: '',
        paymethod: '',
        service: '',
        data: {},
        cashback: 0,
        product: '',
        amountToPay: 0,
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const { data, isLoading } = useQuery({
        queryKey: [`all-airtime-data`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.mobilenig.all_services}?query=airtime`)
            const result = await Authgeturl(Apis.serviceapi.all_airtime_cashback)
            setItems(result.msg)
            return response.msg
        }
    })

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.Service) return ErrorAlert('Select your preferred network')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')
        if (!topups.pay && !forms.Amount) return ErrorAlert('Provide a valid amount')

        const tag = AirtimeServices.find(ele => ele.name === forms.Service)
        const dataToStore = {
            ...single,
            status: true,
            serv: tag.service_id,
            servid: data.id,
            pplan: tag.service_type,
            Number: forms.Number,
            product: tag.name,
        }
        setSingle(dataToStore)
    }

    const PayFromBalance = async (id) => {
        const invoicebody = {
            invoiceid: id
        }
        setLoader(true)
        try {
            const result = await Authposturl(Apis.mobilenig.purchase_airtime, invoicebody)
            if (result.status === 200) {
                // dispatch(dispatchUser(result.user))
                Swals('Successful purchase', result.msg, 'success')
                return navigate('/service_transactions')
            } if (result.status === 201) {
                dispatch(dispatchUser(result.user))
                Swals('Purchase Processing', result.msg, 'info')
                return navigate('/service_transactions')
            } else {
                Swals('Error Purchasing Airtime', `${result.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }

    const handlePriceFee = e => {
        const val = parseFloat(e)
        if (e.length > 0) {
            const actual = val
            const sum = (val * data.charges) / 100 + val
            const priced = (val * data.cashback) / 100
            setSingle({
                ...single,
                amount: sum,
                amountToPay: sum,
                actual,
                cashback: priced
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: '',
                amountToPay: "",
            })
        }
    }
    const SelectTopup = (item) => {
        if (topups.price !== item.price) {
            setTopups(item)
            setForms({
                ...forms,
                Amount: item.pay
            })

            setSingle({
                ...single,
                amount: item.price,
                amountToPay: item.pay,
                actual: item.price,
                cashback: item.cashback
            })
        } else {
            setTopups({})
            setForms({
                ...forms,
                Amount: ""
            })
        }
    }

    const handleServiceSelection = (item) => {
        if (forms.Service !== item) {
            setForms({ ...forms, Service: item })
        } else {
            setForms({ ...forms, Service: '' })
        }
    }
    if (isLoading) return (
        <UserLayout title="Airtime Purchase">
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <div className="">
                        {new Array(5).fill(0).map((ele, index) => (
                            <div className="bg-slate-200 h-16 w-full animate-pulse mb-3" key={index}></div>
                        ))}
                    </div>
                </div>
            </div>
        </UserLayout>
    )

    return (
        <UserLayout title="Airtime Purchase">
            <div className="w-11/12 mx-auto mt-10">
                {single.status && <ServiceConfirmation
                    loading={loader}
                    productTag="Airtime"
                    setLoading={setLoader}
                    single={single}
                    titletag="airtime"
                    setSingle={setSingle}
                    user={user}
                    PayFromBalance={PayFromBalance}
                    closeView={() => setSingle({ ...single, status: false })}
                />}
                <div className="px-3 py-5 bg-white mb-5">
                    <div className="flex items-center justify-between border-b">
                        <div className="font-extrabold text-lg capitalize">top up</div>
                    </div>
                    <div className="mt-3">Choose an amount</div>
                    <div className="grid grid-cols-3 gap-3 mt-3">
                        {items.map((ele, i) => (
                            <div
                                onClick={() => SelectTopup(ele)}
                                className={`${topups.price === ele.price ? 'bg-teal-500' : 'bg-slate-200/50'} cursor-pointer hover:scale-105 border transition-all rounded-lg px-2 py-2 flex flex-col items-center justify-center`} key={i}>
                                <div className={`${topups.price === ele.price ? 'text-white' : 'text-main'} text-2xl font-bold`}> <span className="text-base">{NairaSign}</span>{ele.price.toLocaleString()}</div>
                                <div className={`${topups.price === ele.price ? 'text-white' : 'text-zinc-500'}`}>pay {NairaSign}{ele.pay.toLocaleString()}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <div className="grid grid-cols-4">
                                {ServiceImages.map((image, index) => {
                                    const item = AirtimeServices.find(ele => ele.name.toLowerCase().startsWith(image.tag))
                                    return (
                                        <div
                                            onClick={() => handleServiceSelection(item.name)}
                                            className={`flex flex-col ${forms.Service === item.name ? 'bg-orange-100 font-bold' : 'bg-slate-100'} w-11/12 mx-auto items-center justify-center gap-2 px-3 py-2 rounded-md cursor-pointer`} key={index}>
                                            <img src={image.image} alt="" className="size-10" />
                                            <div className="">{item?.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            {/* <Forminput value={forms.Service} formName={'Service'} onChange={handleForms} formtype='select'>
                                {AirtimeServices.map((ele, index) => (
                                    <option value={ele.name} key={index}>{ele.name}</option>
                                ))}
                            </Forminput> */}
                        </div>
                        <div className="mb-4">
                            <div className="">Phone Number</div>
                            <Forminput type="number" formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        {!topups?.price && <div className="mb-2">
                            <div className="">Amount ({NairaSign})</div>
                            <Forminput type="number" onKeyup={e => handlePriceFee(e.target.value)} label={'0.00'} formName={'Amount'} value={forms.Amount} onChange={handleForms} />
                        </div>}
                        <Formbutton title="subscribe now" loading={loading} />
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}



import React, { useEffect, useRef, useState } from 'react'


const SelectOptions = ({title, setup, options}) => {
    const [text, setText] = useState('')
    const [content, setContent] = useState('')
    const [codes, setCodes] = useState(options || [])
    const togref = useRef()
    const [view, setView] = useState(false)
    
    useEffect(() => {
        togref && window.addEventListener('click', e => {togref.current !== null && !togref.current.contains(e.target) && setView(false) }, true)
    }, [])

    const handleChange = val => {
        setText(val)
        if(val.length > 0) {
            const findText = options.filter(ele => ele.name.toLowerCase().includes(val?.toLowerCase()))
            setCodes(findText)
        }else {
            setCodes(options)
        }
    }
    const handleSelection = val => {
        setContent(`${val.name}`)
        setup(val)
        setView(false)
    }
    return (
        <>
            <div ref={togref} className={`${view ? '' : 'hidden'} absolute top-[5rem] z-10 left-0 w-full bg-white border shadow-2xl`}>
                <div className="w-11/12 mx-auto mt-3">
                    <input value={text} onChange={e => handleChange(e.target.value)} placeholder='Search country here!...' type="text" className="input" />
                    <div className="h-[20rem] overflow-y-auto scrolls">
                        {codes.map((item, i) => (
                            <div onClick={() => handleSelection(item)} className="text-xs p-1.5 border-b cursor-pointer hover:bg-slate-50" key={i}>{item.name}</div>
                        ))}
                    </div>
                </div>
            </div>
            <input className='input' onClick={() => setView(true)} placeholder={title} value={content} readOnly />
        </>
    )
}

export default SelectOptions

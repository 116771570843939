import React from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import { BsBellFill } from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const KycWarning = ({closeView}) => {
    const {user} = useSelector(state => state.data)
    const navigate = useNavigate()
  return (
    <ModalLayout closeView={closeView}>
      <div className="flex items-center gap-4 flex-col py-7 px-4">
        <BsBellFill className='text-sub text-4xl' />
        <div className="text-center">
            <span className="font-bold">Hi {user.firstname}, </span>
            <span className='font-light'>in complience with CBN regulations, we need to verify your identity and approve your account with us. kindly note that your account can be suspended instantly if detected that the informations provided to us are fake / compromised. read all fields carefully before submitted for approval. Thank you </span>
            <div className="mt-5">
                <button 
            onClick={() => navigate('/kyc')}
                className="bg-main text-white capitalize py-3 px-4 w-full rounded-lg">proceed</button>
            </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default KycWarning


import React from 'react'
import UserFooter from './UserFooter'
import Usernavbar from './Usernavbar'
import UserSidenavs from './UserSidenavs'

const UserLayout = ({ children, footer = true, title, navlink, paddings = true, bgmain }) => {
  return (
    <div className=''>
      <div className="bg-zinc-100/60">
        <div className="flex items-center">
          <div className="hidden h-screen border-r lg:w-[20%] bg-white lg:block">
            <UserSidenavs />
          </div>
          <div className="h-screen overflow-hidden w-full lg:w-[80%] ml-auto">
            <div className="">
              <Usernavbar bgmain={bgmain} title={title} navlink={navlink} />
            </div>
            <div className="h-[92dvh] overflow-y-auto scrolls overflow-x-hidden">
              <div className={`w-full h-full pb-5`}>
                <div className={paddings ? 'pb-20' : ''}>
                  {children}
                </div>
                {footer && <div className="lg:hidden h-[10dvh] pt-2">
                  <div className="fixed bottom-0 left-0 w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-4">
                      <div className="lg:block"></div>
                      <div className="col-span-2">
                        <UserFooter />
                      </div>
                      <div className="lg:block"></div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserLayout

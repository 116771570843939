import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { useNavigate } from 'react-router-dom'
import { SlArrowRight, SlExclamation } from 'react-icons/sl'
import { PiChartLineUpLight } from 'react-icons/pi'
import { ErrorAlert, Errors } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import {useSelector} from 'react-redux'

const Invests = () => {
    const {plans} = useSelector(state => state.data)
    const navigate = useNavigate()

    const handleStock = async ({data, dataid}) => {
        try {
            const res = await Authposturl(`${Apis.investments.add_stock}/${dataid}`)
            if(res.status === 200) {
                navigate(`/investment/${res.msg}/${data}`)
            }
        } catch (error) {
            Errors(error)
        }
    }

    return (
        <UserLayout title="Investments">
            <div className="flex flex-col w-11/12 mx-auto gap-4">
                {plans.length < 1 && <div className="text-center text-zinc-500 flex flex-col items-center gap-5 mt-10">
                    <SlExclamation className='text-5xl' />
                    <div className="">There are no available stocks at the moment</div>
                </div>}
                {plans.length > 0 && plans.map((item, k) => (
                    <div onClick={() => handleStock({data: item.title, dataid: item.id})} key={k}>
                        <div className="border cursor-pointer rounded-lg shadow-lg p-2 bg-white">
                            <div className="grid grid-cols-5">
                                <div className="capitalize flex items-center gap-2 col-span-4 font-bold"> <PiChartLineUpLight /> {item.title} investment</div>
                                <div className="col-span-1 w-fit ml-auto text-xs mt-2"> <SlArrowRight /> </div>
                            </div>
                            <div className="text-xs ml-6">{item.subTitle}</div>
                        </div>
                    </div>
                ))}
            </div>
        </UserLayout>
    )
}

export default Invests
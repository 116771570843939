import React, { useEffect, useState } from 'react'
import { BsFillMoonFill, BsFillSunFill } from 'react-icons/bs'

const DarkMode = () => {
    const locals = 'theme'
    const [theme, setTheme] = useState(localStorage.getItem(locals))
    const Icon = theme === 'dark' ? BsFillSunFill : BsFillMoonFill

    // useEffect(() => {
    //     if(window.matchMedia('prefer-color-scheme: dark').matches) {
    //         setTheme('dark')
    //     }else {
    //         setTheme('light')
    //     }
    // }, [])

    useEffect(() => {
        if(theme === 'dark') {
            document.documentElement.classList.add("dark")
            localStorage.setItem(locals, "dark")
        }else {
            document.documentElement.classList.remove("dark")
            localStorage.setItem(locals, "light")
        }
    }, [theme])

    const ToggleMode = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }
  return (
    <button onClick={ToggleMode}> <Icon className="text-xl text-zinc-400" /> </button>
  )
}

export default DarkMode
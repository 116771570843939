
import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import { Errors, NairaSign } from 'components/utils/functions'
import { Apis, Authgeturl } from 'components/Apis'
import moment from 'moment'
import AHeader from 'components/admin/AHeader'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/utils/Spinner'

const Tableheaders = [
    "S/N",
    "Amount",
    "initial balance",
    "final balance",
    "service",
    "transaction ID",
    "type",
    "description",
    "date created",
]

export default function ManageServiceApi () {
    const [plans, setPlans] = useState({})
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)

    const {data, isLoading} = useQuery({
        queryKey: ['api_wallet_balance_admin'],
        queryFn: async () => {
            try {
                const res = await Authgeturl(Apis.mobilenig.wallet_history)
                if (res.status === 200) return res.msg
            } catch (error) {
                Errors(error)
            }
        }
    })

  if(isLoading) return (
    <ALayout>
        <div className="">
            <AHeader
                title="Api Wallet Balance"
                total={`${0}`}
                url={false}
            />
            <div className="mt-10 flex flex-col items-center justify-center gap-5">
                <Spinner />
                <div className="animate-pulse">Loading Data....</div>
            </div>
        </div>
    </ALayout>
  )

  return (
    <ALayout>
        <div className="">
            <AHeader
                title="Api Wallet Balance"
                total={`${NairaSign}${data.balance ?? 0}`}
                url={false}
            />
            <div className="mt-10 overflow-x-auto scrollsdown">
                <table className='table table-auto w-full bg-white p-3 text-sm'>
                    <thead>
                        <tr className='bg-slate-700 text-white'>
                            {Tableheaders.map((ele, i) => (
                                <td className='capitalize p-3 truncate' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.transactions?.map((item, i) => (
                            <tr 
                            key={i}
                            className='border-b border-r border-l'>
                                <td className='p-3 truncate'>{i + 1}</td>
                                <td className='p-3 truncate'>{NairaSign}{item.amount}</td>
                                <td className='p-3 truncate'>{NairaSign}{item.initial_balance}</td>
                                <td className='p-3 truncate'>{NairaSign}{item.final_balance}</td>
                                <td className='p-3 truncate'>{item.service}</td>
                                <td className='p-3 truncate'>{item.trans_id}</td>
                                <td className='p-3 truncate'>{item.type}</td>
                                <td className='p-3 truncate'>{item.description}</td>
                                <td className='p-3 truncate'>{moment(item.date).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </ALayout>
  )
}



import UserLayout from "components/user/UserLayout";
import Imaged from "components/utils/Imaged";
import React, { useState } from "react";
import { BiSolidEditAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import avatar from "assets/images/avatar.png";
import { imgurl } from "components/Apis";
import { MdVerified } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { SuccessAlert } from "components/utils/functions";
import { BsBellFill, BsCopy, BsFillBarChartFill, BsKey } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { BiSolidNews, BiSolidUser } from "react-icons/bi";
import { GoHome } from "react-icons/go";
import { FaShoppingCart } from "react-icons/fa";
import ManagePin from "./ManagePin";
import { LuNetwork, LuUsers } from "react-icons/lu";

export default function Pages() {
  const { user } = useSelector((state) => state.data);
  const image = user.image ? `${imgurl}/profile/${user.image}` : avatar;
  const [view, setView] = useState({
    tag: "",
    data: {},
  });

  const PageLinks = [
    {
      title: "Account Info",
      list: [
        {
          title: "Dashboard",
          path: "/dashboard",
          button: false,
          icon: <GoHome />,
        },
        {
          title: "My Profile",
          path: "/profile",
          button: false,
          icon: <BiSolidUser />,
        },
      ],
    },
    {
      title: "Marketting",
      list: [
        {
          title: "Shortlets",
          path: "/dashboard/shortlets",
          button: false,
          icon: <FaShoppingCart />,
        },
        {
          title: "Pickup Orders",
          path: "/pages/orders",
          button: false,
          icon: <HiMiniShoppingBag />,
        },
        {
          title: 'BizNet',
          path: '/biznet',
          button: false,
          icon: <LuNetwork />
        },
        {
          title: 'Referrals',
          path: '/referrals',
          button: false,
          icon: <LuUsers />
        },
      ],
    },
    {
      title: "Transactions",
      list: [
        {
          title: "Transactions",
          path: "/transactions",
          button: false,
          icon: <BsFillBarChartFill />,
        },
        {
          title: "Cashbacks Transactions",
          path: "/cashbacks",
          button: false,
          icon: <BsFillBarChartFill />,
        },
        {
          title: "Transaction Pin",
          path: "",
          button: true,
          icon: <BsKey />,
        },
      ],
    },
    {
      title: "Notifications",
      list: [
        {
          title: "News Feeds",
          path: "/feeds",
          button: false,
          icon: <BiSolidNews />,
        },
        {
          title: "Notifications",
          path: "/notifications",
          button: false,
          icon: <BsBellFill />,
        },
      ],
    },
  ];

  const OpenModal = (val) => {
    setView({ ...view, tag: val });
  };

  return (
    <UserLayout footer={false} title={"Other Pages"} navlink="/dashboard">
      {view.tag === 'Transaction Pin' && (
        <ManagePin closeView={() => setView({ ...view, tag: "" })} />
      )}
      <div className="w-11/12 mx-auto">
        <div className="bg-white border rounded-xl flex items-center p-4">
          <div className="w-full flex gap-3">
            <div className="">
              
              <Imaged
                src={image}
                alt=""
                className="size-16 object-cover rounded-full"
              />
            </div>
            <div className="">
              <div className="font-bold">
                {user.firstname} {user.lastname}
              </div>
              <CopyToClipboard
                text={user.refid}
                onCopy={() => SuccessAlert("Referral code copied")}
              >
                <div className="flex cursor-pointer items-center gap-2 text-sm">
                  <span>{user.refid}</span> <BsCopy />
                </div>
              </CopyToClipboard>
              <div
                className={`${
                  user?.kycstatus === "approved" ? "bg-blue-600" : "bg-red-600"
                } rounded-lg px-3 text-white text-xs font-bold py-1.5 w-fit`}
              >
                {user.kycstatus === "approved" ? (
                  <span className="flex items-center gap-2">
                    
                    <MdVerified /> Verified
                  </span>
                ) : (
                  <span className="flex items-center gap-2">
                    
                    <FaTimes /> Not Verified
                  </span>
                )}
              </div>
            </div>
          </div>
          <Link to="/profile" className="border bg-slate-100 p-3 rounded-xl cursor-pointer">
            
            <BiSolidEditAlt />
          </Link>
        </div>
        <div className="">
          {PageLinks.map((item, index) => (
            <div className="" key={index}>
              <div className="ml-5 py-3 font-bold">{item.title}</div>
              <div className="bg-white px-4 rounded-xl border flex flex-col">
                {item.list.map((page, i) =>
                  page.button ? (
                    <div
                      key={i}
                      className="py-2 border-b last:border-none cursor-pointer"
                      onClick={() => OpenModal(page.title)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="">{page.title}</div>
                        <div className="text-xl">{page.icon}</div>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={`${page.path}`}
                      key={i}
                      className="px-4 py-2 border-b last:border-none"
                    >
                      <div className="flex items-center justify-between">
                        <div className="">{page.title}</div>
                        <div className="text-xl">{page.icon}</div>
                      </div>
                    </Link>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </UserLayout>
  );
}

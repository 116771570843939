import UserLayout from 'components/user/UserLayout'
import { Apis, Authgeturl } from 'components/Apis'
import { NairaSign, ServiceTransactionStatus } from 'components/utils/functions'
import Spinner from 'components/utils/Spinner'
import { BiSolidBellRing } from 'react-icons/bi'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'
import { useQuery } from '@tanstack/react-query'

export default function ServiceTransactions () {
    const navigate = useNavigate()

    const { data, isLoading } = useQuery({
        queryKey: ['mobile-nig-all-transactions'],
        queryFn: async () => {
            const response = await Authgeturl(Apis.mobilenig.all_transactions)
            return response.msg
        }
    })

    if (isLoading) return (
        <UserLayout title="Service Transactions">
            <div className="">
                <div className="font-bold text-3xl w-11/12 mx-auto mb-5">Service Transactions</div>
                <div className="w-fit mx-auto mt-20"> <Spinner /> </div>
            </div>
        </UserLayout>
    )
    return (
        <UserLayout title="Service Transactions">
            <div className="">
                <div className="font-bold text-3xl w-11/12 mx-auto mb-5">Service Transactions</div>
                <div className="bg-white p-4">
                    <ol className="relative border-l border-gray-200 dark:border-gray-300 w-11/12 mx-auto">
                        {data.length > 0 && data.map((item, index) => (
                            <li key={index} className="mb-10 ml-6 cursor-pointer" onClick={() => navigate(`/service_transactions/${item.id}`)}>
                                <span className={`absolute flex items-center text-sm justify-center w-8 h-8  rounded-full -left-4 ring-4  ${ServiceTransactionStatus(item.status)}`}>
                                    <BiSolidBellRing />
                                </span>
                                <div className="grid grid-cols-5">
                                    <div className="col-span-5">
                                        <div className="grid grid-cols-2 gap-2 text-xs">
                                            <div className='font-bold'></div>
                                            <div className='text-right uppercase'><button className='w-full flex justify-end'> <FaArrowRight /> </button></div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 text-xs">
                                            <div className='font-bold'>Amount:</div>
                                            <div className='text-right uppercase'>{NairaSign}{item.amount?.toLocaleString()}</div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 text-xs">
                                            <div className='font-bold'>Purchase:</div>
                                            <div className='text-right uppercase'>{item.action === 'ELE' ? 'Electricity' : item.action}</div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2 text-xs">
                                            <div className='font-bold'>Status:</div>
                                            <div className='text-right'>{item.status}</div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2 text-xs break-words">
                                            <div className='font-bold'>Reference:</div>
                                            <div className='break-words col-span-2 text-right'>{item.ref}</div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2 text-xs break-words">
                                            <div className='font-bold'>Date Initiated:</div>
                                            <div className='break-words col-span-2 text-right'>{moment(item.createdAt).format('DD-MM-YYYY')}</div>
                                        </div>
                                        <div className="text-xs"> <span className="font-bold">Message: </span> {item.desc}</div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
        </UserLayout>
    )
}


import React, { useState } from 'react'
import { BsBellFill } from 'react-icons/bs'
import { TbHeadset } from 'react-icons/tb'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DarkMode from '../utils/DarkMode'
import { FaArrowLeft } from 'react-icons/fa'
import ConfirmationModal from '../utils/ConfirmationModal'
import { Apis, Authposturl } from '../Apis'
import Cookies from 'js-cookie'
import { ErrorAlert, SuccessAlert, tokenName } from '../utils/functions'
import { useSelector } from 'react-redux'
import { SlPower } from 'react-icons/sl'
import logo from 'assets/main-images/main.png'
import Imaged from '../utils/Imaged'

const Usernavbar = ({ title, navlink="/dashboard", bgmain="bg-main" }) => {
    const location = useLocation()
    const home = '/dashboard'
    const { user, alerts } = useSelector(state => state.data)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmission = async () => {
        setLoading(true)
        try {
            const res = await Authposturl(Apis.user.logout)
            setLoading(false)
            if (res.status === 200) {
                Cookies.set(tokenName, res.token)
                SuccessAlert(res.msg)
                navigate('/login')
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }
    return (
        <>
            {show && <ConfirmationModal
                closeView={() => setShow(!show)}
                mainbg="bg-red-500"
                loading={loading}
                handleSubmission={handleSubmission}
                title={`Hi ${user.firstname}, Are you sure you want to logout`}
            />}
            <div className={bgmain}>
                <div className="flex items-center justify-between p-5">
                    <div className="flex items-center gap-7">
                        {/* <button> <SlMenu className='text-xl text-main' /> </button> */}
                        <div className="flex items-center gap-2">
                            <div onClick={() => navigate(`${navlink}`)} className="flex items-center gap-2 cursor-pointer">
                            {location.pathname !== home && <FaArrowLeft className={`cursor-pointer ${bgmain ? 'text-white' : 'text-main'}`} />}
                            <div className={`capitalize font-semibold ${bgmain ? 'text-white' : 'text-main'}`}>{title}</div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="flex justify-end gap-8 item-center">
                            {/* <DarkMode /> */}
                            <div className="relative">
                                <Link to={`/support`} className={`text-2xl ${bgmain ? 'text-white' : 'text-main'}`}> <TbHeadset /> </Link>
                            </div>
                            <Link to={`/notifications`} className="relative">
                                <div className="bg-red-500 text-[0.55rem] absolute -top-2 -right-2 font-medium py-[0.05rem] border border-white px-[0.2rem] text-center flex items-center justify-center rounded-full text-white">{alerts?.unreads < 10 ? `0${alerts?.unreads}` : alerts?.unreads > 9 ? `${alerts?.unreads}+` : alerts?.unreads}</div>
                                <div className={`text-2xl ${bgmain ? 'text-white' : 'text-main'}`}> <BsBellFill /> </div>
                            </Link>
                            <div onClick={() => setShow(!show)} className={`text-2xl cursor-pointer ${bgmain ? 'text-white' : 'text-main'}`}> <SlPower /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Usernavbar

import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { DataServices, ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import { useNavigate } from 'react-router-dom'
import { dispatchUser } from 'app/reducer'
import { useDispatch, useSelector } from 'react-redux'
import ServiceConfirmation from './ServiceConfirmation'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/utils/Spinner'
import { ServiceImages } from 'components/utils/PageUtils'
import Selectinput from 'components/utils/Selectinput'
import { MenuItem } from '@mui/material'

export default function Data() {
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.data)
    const [plans, setPlans] = useState([])
    const [forms, setForms] = useState({
        network: '',
        Action: "DATA",
        Service: "",
        Serviceid: '',
        Plan: "",
        Number: "",
        actual: ''
    })
    const [single, setSingle] = useState({
        status: false,
        cashback: 0,
        amount: 0,
        paymethod: '',
        service: '',
        data: {},
        product: '',
        amountToPay: 0,
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.Serviceid) return ErrorAlert('Select your preferred network')
        if (!forms.Plan) return ErrorAlert('Select a Bundle to purchase')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')

        const tag = DataServices.find(ele => ele.service_id === forms.Serviceid)
        const item = tag.bundles.find(ele => forms.Plan === ele.productCode)
        const dataToStore = {
            ...single,
            status: true,
            serv: tag.service_id,
            servid: data.id,
            pplan: tag.requestType,
            Number: forms.Number,
            product: item.name,
        }
        setSingle(dataToStore)
    }

    const PayFromBalance = async (id) => {
        const invoicebody = {
            invoiceid: id
        }
        setLoader(true)
        try {
            const result = await Authposturl(Apis.mobilenig.purchase_data, invoicebody)
            if (result.status === 200) {
                dispatch(dispatchUser(result.user))
                Swals('Successful purchase', result.msg, 'success')
                return navigate('/service_transactions')
            } if(result.status === 201) {
                dispatch(dispatchUser(result.user))
                Swals('Purchase Processing', result.msg, 'info')
                return navigate('/service_transactions')
            } else {
                Swals('Error Purchasing Data', `${result.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }
    

    
    const { data, isLoading } = useQuery({
        queryKey: [`all-data-services-for-users`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.mobilenig.all_services}?query=data`)
            return response.msg
        }
    })

    const handleService = (val) => {
        if (val) {
            const item = DataServices.find(ele => val.startsWith(ele.service_id))
            setSingle({
                ...single,
                service: item.title
            })

            setForms({
                ...forms,
                network: val,
                Serviceid: item.service_id,
                Service: item.title
            })
            setLoading(true)
            setTimeout(() => {
                const output = []
                item.bundles.map(ele => {
                    const values = {
                        ...ele
                    }
                    return output.push(values)
                })
                setPlans(output)
                setLoading(false)
            }, 1000);

        } else {
            setForms({
                ...forms,
                network: '',
                Serviceid: '',
            })
        }
    }

    const handlePlans = e => {
        const val = e.target.value
        const findService = DataServices.find(ele => forms.Serviceid === ele.service_id)
        const item = findService.bundles.find(ele => val === ele.productCode)
        if (val) {
            const actual = item.price
            const sum = (item.price * data.charges) / 100 + item.price
            const priced = (item.price * data.cashback) / 100
            setForms({
                ...forms,
                Plan: val,
                actual: item.price
            })
            setSingle({
                ...single,
                amount: sum,
                amountToPay: sum,
                actual,
                Plan: val,
                cashback: priced
            })
        } else {
            setForms({
                ...forms,
                Plan: "",
                actual: ''
            })
            setSingle({
                ...single,
                status: false,
                amount: '',
                Plan: '',
                amountToPay: "",
            })
        }
    }
    
    if (isLoading) return (
        <UserLayout title="Data Purchase">
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <div className="">
                        {new Array(5).fill(0).map((ele, index) => (
                            <div className="bg-slate-200 h-16 w-full animate-pulse mb-3" key={index}></div>
                        ))}
                    </div>
                    <div className="mt-10">
                        <div className="">
                            <div className="mt-5 mb-2 text-xl font-bold">Check Data Balance</div>
                            <div className='mb-1'>Note: MTN SME = *461*4#</div>
                            <div className='mb-1'>Note: MTN SME2 = *323*4#</div>
                            <div className='mb-1'>Note: MTN CG = *460*260#</div>
                            <div className='mb-1'>Note: Glo = *323#</div>
                            <div className='mb-1'>Note: Airtel = *323#</div>
                            <div className='mb-1'>Note: 9mobile = *228#</div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )

    
    return (
        <UserLayout title="Data Purchase">
            {single.status && <ServiceConfirmation
                    loading={loader}
                    productTag="Data"
                    setLoading={setLoader}
                    single={single}
                    titletag="data"
                    setSingle={setSingle}
                    user={user}
                    PayFromBalance={PayFromBalance}
                    closeView={() => setSingle({ ...single, status: false })}
            />}
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <div className="grid grid-cols-4">
                                {ServiceImages.map((image, index) => {
                                    const item = DataServices.find(ele => ele.title.toLowerCase().startsWith(image.tag))
                                    return (
                                        <div
                                            onClick={() => handleService(item.service_id)}
                                            className={`flex flex-col ${forms.network === item.service_id ? 'bg-orange-100 font-bold' : 'bg-slate-100'} w-11/12 mx-auto items-center justify-center gap-2 px-3 py-2 rounded-md cursor-pointer`} key={index}>
                                            <img src={image.image} alt="" className="size-10" />
                                            <div className="">{item?.title}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {loading && <Spinner />}
                        {forms.network && <div className="relative mb-4">
                            <div className="">Select Bundle</div>
                            <Selectinput  value={forms.Plan} formName={'Plan'} onChange={handlePlans}>
                                {plans?.map((ele, index) => (
                                    <MenuItem value={ele.productCode} key={index}>{ele.name?.split(' ')[0]} = {NairaSign}{parseInt((ele.price * data.charges) / 100 + ele.price)?.toLocaleString()}</MenuItem>
                                ))}
                            </Selectinput>
                             </div>}
                        <div className="mb-4">
                            <div className="">Phone Number</div>
                            <Forminput type="number" formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        <Formbutton title="purchase data" />
                    </form>
                    <div className="mt-10">
                        <div className="">
                            <div className="mt-5 mb-2 text-xl font-bold">Check Data Balance</div>
                            <div className='mb-1'>Note: MTN SME = *461*4#</div>
                            <div className='mb-1'>Note: MTN SME2 = *323*4#</div>
                            <div className='mb-1'>Note: MTN CG = *460*260#</div>
                            <div className='mb-1'>Note: Glo = *323#</div>
                            <div className='mb-1'>Note: Airtel = *323#</div>
                            <div className='mb-1'>Note: 9mobile = *228#</div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}

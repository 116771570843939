import React, { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'

const ModalLayout = ({children, closeView}) => {
    const togref = useRef()
    useEffect(() => {
        if(togref) {
            window.addEventListener('click', e => {
                togref.current !== null && !togref.current.contains(e.target) && closeView()
            }, true)
        }
    })
  return (
    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen bg-black/60 backdrop-blur-md'>
        <div ref={togref} className="relative w-11/12 max-w-xl mx-auto bg-white rounded-lg foil h-fit max-h-[85dvh] overflow-y-auto scrolls">
            <div onClick={() => closeView()} className="absolute p-2 rounded-full cursor-pointer top-2 right-2 bg-slate-100 text-slate-700"> <FaTimes /> </div>
            {children}
        </div>
    </div>
  )
}

export default ModalLayout
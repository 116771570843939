import React from 'react'
import { BsFillBarChartFill } from 'react-icons/bs'
import {LuNetwork, LuSettings} from 'react-icons/lu'
import {GoHome} from 'react-icons/go'
import { Link } from 'react-router-dom'

const cs = 'flex items-center flex-col hover:text-sub text-zinc-600'
const ti = 'p-2 bg-slate-200 border-white border-4 rounded-full absolute -top-5 left-0 relative hover:scale-150 active:scale-150 focus:scale-150'
const UserFooter = () => {
  return (
    <div className=' bg-white'>
        <div className="grid grid-cols-4 pb-4 border-t">
            <Link to='/dashboard' className={`${cs}`}>
                <div className={`${ti}`}> <GoHome className='text-2xl' /> </div>
                <div className="text-[0.65rem] -mt-4 capitalize">home</div>
            </Link>
            <Link to="/investment" className={`${cs}`}>
                <div className={`${ti}`}> <BsFillBarChartFill className='text-2xl' /> </div>
                <div className="text-[0.65rem] -mt-4 capitalize">investments</div>
            </Link>
            <Link to='/biznet' className={`${cs}`}>
                <div className={`${ti}`}> <LuNetwork className='text-2xl' /> </div>
                <div className="text-[0.65rem] -mt-4">BizNet</div>
            </Link>
            <Link to='/pages' className={`${cs}`}>
                <div className={`${ti}`}> <LuSettings className='text-2xl' /> </div>
                <div className="text-[0.65rem] -mt-4 capitalize">more</div>
            </Link>
        </div>
    </div>
  )
}

export default UserFooter
import { dispatchUser } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import UserLayout from 'components/user/UserLayout'
import BankOptions from 'components/utils/BankOptions'
import ConfirmationModal from 'components/utils/ConfirmationModal'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, NairaSign, SuccessAlert } from 'components/utils/functions'
import Spinner from 'components/utils/Spinner'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SendOptions = [
    "Send to Bank",
    "Across Account"
]

export default function SendMoney() {
    const [forms, setForms] = useState({
        amount: '',
        info: '',
    })
    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(false)
    const [load2, setLoad2] = useState(false)
    const [confirm, setConfirm] = useState({
        status: false,
        name: ''
    })
    const [active, setActive] = useState(SendOptions[0])
    const [views, setViews] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [details, setDetails] = useState({
        bank: '',
        accname: '',
        accnumber: '',
        amount: '',
        reason: '',
    })

    const handleDetails = e => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const handleConfirmation = async (e) => {
        const value = e.target.value
        if (!forms.amount) return ErrorAlert('Provide amount to send across')
        if (!forms.info) return ErrorAlert('Provide receiver phone number / email address')
        setConfirm({ ...confirm, status: false })
        try {
            if (value.length >= 3) {
                setLoading(true)
                const response = await Authposturl(Apis.user.confirm_user_to_send_money, { info: forms.info })
                if (response.status !== 200) return ErrorAlert(`${response.msg}`)
                await new Promise((resolve, reject) => setTimeout(resolve, 3000))
                setConfirm({ status: true, name: response.msg })
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    const NextLevel = () => {
        if (!forms.amount) return ErrorAlert('Provide amount to send across')
        if (!forms.info) return ErrorAlert('Provide receiver phone number / email address')
        setViews(true)
    }
    const handleTransfer = async () => {
        setLoad(true)
        try {
            const response = await Authposturl(Apis.user.send_money, forms)
            if (response.status !== 200) return ErrorAlert(`${response.msg}`)
            await new Promise((resolve, reject) => setTimeout(resolve, 3000))
            SuccessAlert(response.msg)
            dispatch(dispatchUser(response.user))
            return navigate('/transactions')
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoad(false)
        }
    }

    const Setup = (val) => {
        setDetails({ ...details, bank: val.bank_name })
    }
    const SpendCash = async () => {
        if (!details.bank) return ErrorAlert('Provide Bank name')
        if (!details.accname) return ErrorAlert('Provide account name')
        if (!details.accnumber) return ErrorAlert('Provide account number')
        if (!details.amount) return ErrorAlert('Provide amount to send across')
        if (!details.reason) return ErrorAlert('Provide reason for transaction')
        setLoad2(true)
        try {
            const response = await Authposturl(Apis.user.send_money_to_bank, details)
            if (response.status !== 200) return ErrorAlert(`${response.msg}`)
            await new Promise((resolve, reject) => setTimeout(resolve, 3000))
            SuccessAlert(response.msg)
            dispatch(dispatchUser(response.user))
            return navigate('/transactions')
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoad2(false)
        }
    }
    return (
        <UserLayout title="Send Money">
            {views && <ConfirmationModal
                title={`Confirm your request to send ${NairaSign}${forms.amount?.toLocaleString()} to ${confirm.name}'s account`}
                handleSubmission={handleTransfer}
                loading={load}
                closeView={() => setViews(false)}
            />}
            <div className="bg-white p-3 rounded-lg w-[98%] mt-5 mx-auto">
                <div className="grid grid-cols-2 gap-10">
                    {SendOptions.map((item, index) => (
                        <div onClick={() => setActive(item)} key={index} className={`cursor-pointer text-center p-3 rounded-lg ${active === item ? 'bg-main text-white' : 'bg-slate-200'} transition-all`}>
                            <div className="">{item}</div>
                        </div>
                    ))}
                </div>
            </div>
            {active === SendOptions[0] && <div className="bg-white p-3 rounded-lg w-[98%] mt-5 mx-auto">
                
                    <div className="border-b pb-3 mb-3 pt-5">
                        <div className="font-bold text-2xl">Send Money to Bank</div>
                        <div className="text-sm text-slate-500">Money sent across to a local bank will be debited from your wallet instantly, kindly confirm your actions before proceeding.</div>
                    </div>

                    <div className=''>
                        <div className='mb-10'></div>
                        <div className="mb-4 relative">
                            <div className="">Commercial Institution</div>
                            <BankOptions defaultValue={details.bank} title="--Select--" setup={Setup} />
                        </div>
                        <div className="mb-3">
                            <div className="text-sm">Account Holder Name</div>
                            <Forminput
                                value={details.accname}
                                formName={'accname'}
                                onChange={handleDetails}
                            />
                        </div>
                        <div className="mb-3">
                            <div className="text-sm">Account Number</div>
                            <Forminput
                                value={details.accnumber}
                                formName={'accnumber'}
                                onChange={handleDetails}
                            />
                        </div>
                        <div className="mb-3">
                            <div className="text-sm">Amount to withdraw ({NairaSign})</div>
                            <Forminput
                                value={details.amount}
                                onChange={handleDetails}
                                formName={'amount'}
                                label={'0.00'}
                                type="number"
                            />
                        </div>
                        <div className="mb-3">
                            <div className="text-sm">Reason</div>
                            <Forminput
                                value={details.reason}
                                onChange={handleDetails}
                                formName={'reason'}
                                label={'Provide a Reason'}
                            />
                        </div>
                        <Formbutton
                            handleSubmission={SpendCash}
                            loading={load2}
                            title={'withdraw'}
                        />
                    </div>
                
            </div>}
            {active === SendOptions[1] && <div className="bg-white p-3 rounded-lg w-[98%] mt-5 mx-auto">
                <div className="border-b pb-3 mb-3 pt-5">
                    <div className="font-bold text-2xl">Send money across accounts</div>
                    <div className="text-sm text-slate-500">Money sent across to another account will be debited from your wallet instantly, kindly confirm your actions before proceeding.</div>
                </div>
                <div>
                    <Forminput value={forms.amount} formName="amount" onChange={handleForms} type="number" content="Amount to send" />
                    <Forminput readOnly={loading ? true : false} value={forms.info} formName="info" onChange={handleForms} onKeyup={loading ? null : handleConfirmation} content="User Phone Number/Email address" />
                    <div className="">
                        {loading && <Spinner />}
                        {confirm.status && <div className="text-green-600">{confirm.name}</div>}
                    </div>
                    <div className="w-2/5 ml-auto">
                        <Formbutton handleSubmission={NextLevel} loading={load} title={'Send money'} />
                    </div>
                </div>
            </div>}
        </UserLayout>
    )
}

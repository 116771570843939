import { MdVerified } from "react-icons/md"
import { SlArrowDown, SlArrowUp } from "react-icons/sl"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import avatar from 'assets/images/avatar.png'
import logo from 'assets/main-images/main.png'
import { imgurl } from "../Apis"
import Imaged from "../utils/Imaged"
import { useState } from "react"
import { NairaSign } from "components/utils/functions"



const Links = [
    {
        title: `dashboard`,
        url: `/dashboard`,
        links: []
    },
    {
        title: `investment`,
        url: `/investment`
    },
    {
        title: `fund wallet`,
        url: `/fund-account`
    },
    {
        title: 'shortlets',
        urls: false,
        links: [
            {
                title: `shortlets`,
                url: `/dashboard/shortlets`
            },
            {
                title: `pickup orders`,
                url: `/pages/orders`
            },
        ]
    },
    {
        title: 'transactions',
        url: false,
        links: [
            {
                title: `transactions`,
                url: `/transactions`
            },
            {
                title: `service transactions`,
                url: `/service_transactions`
            },
            {
                title: `cashback transactions`,
                url: `/cashbacks`
            },
        ]
    },
    {
        title: 'Services',
        url: false,
        links: [
            {
                title: `airtime`,
                url: `/airtime`
            },
            // {
            //     title: `data`,
            //     url: `/data`
            // },
            // {
            //     title: `electricity`,
            //     url: `/electricity`
            // },
            // {
            //     title: `cable`,
            //     url: `/cable`
            // },
            // {
            //     title: `airtime to cash`,
            //     url: `/airtime_to_cash`
            // },
        ]
    },
    {
        title: `feeds`,
        url: `/feeds`
    },
    {
        title: "Settings",
        url: false,
        links: [
            {
                title: `notifications`,
                url: `/notifications`
            },
            {
                title: `kyc`,
                url: `/kyc`
            },
            {
                title: `profile`,
                url: `/profile`
            },
            {
                title: `support`,
                url: `/support`
            },
        ]
    },
]

export default function UserSidenavs() {
    const { user } = useSelector(state => state.data)
    const [active, setActive] = useState('')

    const HandleActive = tag => {
        if (active !== tag) {
            setActive(tag)
        } else {
            setActive('')
        }
    }
    return (
        <div>
            <div className="h-[27dvh] border-b">
                <div className="relative flex flex-col items-center justify-center gap-2 mx-auto pt-7 w-fit">
                    <Imaged src={logo} alt="" className="h-5 w-auto" />
                    <div className=""><Imaged src={user?.image ? `${imgurl}/profile/${user?.image}` : avatar} alt="" className="w-16 h-16 rounded-full object-cover" /></div>
                    <div className="flex flex-col items-center">
                        <div className="flex font-bold text-lg capitalize">{user.firstname} {user.lastname} {user?.kycstatus === 'approved' && <span className="text-blue-600"> <MdVerified /> </span>} </div>
                        <div className="text-sm">{user.phone}</div>
                        <div className="text-sm font-bold">{NairaSign}{user.currbal?.toLocaleString()}</div>
                    </div>
                </div>
            </div>
            <div className="h-[73dvh] overflow-y-auto scrolls pb-24 px-3">
                <div className="flex flex-col">
                    {Links.map((item, index) => (
                        <div
                            key={index}>
                            {item.url ?
                                <div className={`cursor-pointer border-b last:border-none border-slate-300 ${active === index ? 'bg-main text-white' : 'hover:bg-slate-200'}`}>
                                    <Link
                                        className=""
                                        to={`${item.url}`}>
                                        <div className="flex items-center justify-between p-3 capitalize">
                                            <div className="">{item.title}</div>
                                        </div>
                                    </Link>
                                </div> :
                                <div className="border-b last:border-none border-slate-300">
                                    <div
                                        onClick={() => HandleActive(index)}
                                        className={`cursor-pointer ${active === index ? 'bg-main text-white' : 'hover:bg-slate-200'}`}
                                        to={`${item.url}`}>
                                        <div className="flex items-center justify-between p-3 capitalize">
                                            <div className="">{item.title}</div>
                                            {active === index ? <SlArrowUp /> : <SlArrowDown />}
                                        </div>
                                    </div>
                                    {active === index && <div className="flex flex-col">
                                        {item.links.map((element, i) => (
                                            <Link
                                                key={i}
                                                className="transition-all px-5 py-2 text-sm capitalize  hover:bg-slate-200"
                                                to={`${element.url}`}>
                                                {element.title}
                                            </Link>
                                        ))}
                                    </div>}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
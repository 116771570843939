
// import React, { useEffect, useState } from 'react'
// import ALayout from 'components/admin/ALayout'
// import { Errors } from 'components/utils/functions'
// import { Apis, Authgeturl } from 'components/Apis'
// import { Link } from 'react-router-dom'
// import Backbutton from 'components/utils/Backbutton'

// export default function NewService() {
//     const [loading, setLoading] = useState(true)
//     const [services, setServices] = useState([])
//     useEffect(() => {
//         const FetchAllServices = async () => {
//             setLoading(true)
//             try {
//                 const response = await Authgeturl(Apis.serviceapi.all_services)
//             if(response.status === 200) { 
//                 const dataArray = Object.entries(response.msg.data).map(([key, value]) => ({ service: key, availability: value }));
//                 const airtimeArray = Object.entries(response.msg.airtime).map(([key, value]) => ({ service: key, availability: value }));
//                 const cableArray = Object.entries(response.msg.cable).map(([key, value]) => ({ service: key, availability: value }));
//                 const eleArray = Object.entries(response.msg.ele).map(([key, value]) => ({ service: key, availability: value }));
//                 const examArray = Object.entries(response.msg.exam).map(([key, value]) => ({ service: key, availability: value }));

//                 const finals = [
//                     {
//                         item: "data",
//                         value: "data",
//                         options: dataArray
//                     },
//                     {
//                         item: "airtime",
//                         value: "airtime",
//                         options: airtimeArray
//                     },
//                     {
//                         item: "cable",
//                         value: "cable",
//                         options: cableArray
//                     },
//                     {
//                         item: "electricity",
//                         value: "ele",
//                         options: eleArray
//                     },
//                     {
//                         item: "exam",
//                         value: "exam",
//                         options: examArray
//                     },
//                 ]
//                 return setServices(finals)
//             }
//             } catch (error) {
//                 Errors(`${error.message}`)
//             }finally {
//                 setLoading(false)
//             }
//         }
//         FetchAllServices()
//     }, [])
//   return (
//     <ALayout>
//         <div className="flex items-center gap-5">
//     <Backbutton link="/admin/services" />
//         <div className="text-4xl font-bold mb-2">All Services</div>
//         </div>
//         <div className="mb-5">Setting up margin will influence the price of each services at the time of purchase</div>
//         {loading && <div className="text-center mt-20">Loading contents...</div> }
//         <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
//             {!loading && 
//             <>
//             {services.map((ele, index) => (
//                 <div className="bg-white rounded-lg p-2 h-fit" key={index}>
//                     <div className="bg-main p-3 text-white rounded-lg text-xs capitalize grid grid-cols-2">
//                         <div className="">{ele.item}</div>
//                         <div className="w-fit ml-auto bg-[#444] capitalize py-1 px-4 rounded-lg"> <Link to={`/admin/services/${ele.value}`}>setup margin</Link> </div>
//                     </div>
//                     <div className="p-1">
//                         {ele.options.map((item, i) => (
//                             <div className="grid grid-cols-2 p-2 text-xs" key={i}>
//                                 <div className="">{item.service}</div>
//                                 <div className={`${['AVALIABLE', 'AVAILABLE'].includes(item.availability) ? 'bg-green-200' : 'bg-red-200'} py-2 text-center`}>{item.availability}</div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             ))}
//             <div className="bg-white rounded-lg p-2 h-fit">
//             <div className="bg-main p-3 text-white rounded-lg text-xs capitalize grid grid-cols-2">
//                 <div className="">Airtime to cash</div>
//                 <div className="w-fit ml-auto bg-[#444] capitalize py-1 px-4 rounded-lg"> <Link to={`/admin/services/a2c`}>setup margin</Link> </div>
//             </div>
//             <div className="p-1">
//                 <div className="text-center text-sm text-slate-400">Attach network service</div>
//             </div>
//         </div>
//             </>
//             }
//         </div>
//     </ALayout>
//   )
// }


import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ALayout from 'components/admin/ALayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import Backbutton from 'components/utils/Backbutton'


const ServiceOptions = [
    {title: 'airtime', name: 'Airtime'},
    {title: 'data', name: 'Data'},
]

export default function NewService() {
    const [loads, setLoads] = useState(false)
    const navigate = useNavigate()
    const [forms, setForms] = useState({
        cashback: '',
        fee: '',
        name: ''
    })    
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    
    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.fee) return ErrorAlert('Provide Fee to be charged')
        if (!forms.cashback) return ErrorAlert('Provide Cashback to be supplied upon purchase')
        const formdata = {
            cashback: forms.cashback,
            charges: forms.fee,
            servicename: forms.name,
            tag: 'create'
        }
        setLoads(true)
        try {
            const response = await Authposturl(Apis.mobilenig.create_service, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                navigate(`/admin/services`)
            } else {
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoads(false)
        }
    }
    
    return (
        <ALayout>
            <Backbutton link="/admin/services" />
            <form onSubmit={handleSubmission} className="grid grid-cols-1 gap-5">
                <div className="bg-white rounded-lg">
                    <div className="border-b p-4">
                        <div className="font-bold text-2xl">Set up Service</div>
                    </div>
                    <div className='p-4'>
                        <div className="mb-3">
                            <div className="">Service Name</div>
                            <Forminput formtype='select' formName={'name'} value={forms.name} onChange={handleForms}>
                                {ServiceOptions.map((item, index) => (
                                    <option key={index} value={item.title}>{item.name}</option>
                                ))}
                            </Forminput>
                            {/* <input name="name" value={forms.name} onChange={handleForms} type="text" className='w-full border p-3 rounded border-zinc-300' /> */}
                        </div>
                        <div className="mb-3">
                            <div className="">Service Purchase fee (%)</div>
                            <input name="fee" value={forms.fee} onChange={handleForms} type="number" className='w-full border p-3 rounded border-zinc-300' />
                        </div>
                        <div className="mb-3">
                            <div className="">Cashback returned (%)</div>
                            <Forminput formName="cashback" value={forms.cashback} onChange={handleForms} type="number" />
                        </div>
                    </div>
                </div>
                <div className="w-fit ml-auto">
                    <Formbutton loading={loads} title="Save Service" />
                </div>
            </form>
        </ALayout>
    )
}




import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

export default function Selectinput({ formName, value, onChange, children, content, label }) {
    return (
        <div className='mb-3'>
            <div className="">{content}</div>

            <FormControl fullWidth>
                <InputLabel className='bg-white py-1 px-4' htmlFor="component-outlined">{label}</InputLabel>

                <Select
                    labelId="demo-simple-select-label"
                    name={formName} value={value} onChange={onChange}
                    label={"Select"}
                    className="!input w-full py-2"
                >
                    <MenuItem value={''}>--Select--</MenuItem>
                    {children}
                </Select>
            </FormControl>
        </div>
    )
}

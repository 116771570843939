import { dispatchUser } from "app/reducer";
import { Apis, Authposturl } from "components/Apis";
import Formbutton from "components/utils/Formbutton";
import Loading from "components/utils/Loading";
import ModalLayout from "components/utils/ModalLayout";
import { ErrorAlert, SuccessAlert, Swals } from "components/utils/functions";
import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch } from "react-redux";

export default function ConfirmSwitch({ closeView, account, refetch }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const handleSubmission = async () => {
    setLoading(true)
    try {
      // setTimeout(() => {
      //   Swals('Hold on...', `Setting account up in progress, activation starts from 18th August 2024 6:00 PM`, 'info')
      //   setLoading(false)
      // }, 2000);
      const formData = {
        accountid: account.id
      }
      const response = await Authposturl(Apis.user.activate_account_plan, formData)
      if (response.status !== 200) return ErrorAlert(`${response.message}`)
      SuccessAlert(`${response.message}`)
      dispatch(dispatchUser(response.user))
      refetch()
      return closeView()
    } catch (error) {
      ErrorAlert(`${error.message}`);
    } finally {
      setLoading(false)
    }
  };
  return (
    <ModalLayout closeView={closeView}>
      {loading && <Loading />}
      <div className="p-4">
        <div className="font-bold text-lg border-b py-3">
          Kindly confirm your request
        </div>
        <div className="flex gap-2 py-1">

          <BsArrowRight className="mt-2" /> This will immediately terminate any
          active account plan you have at the moment if there is any.
        </div>
        <div className="flex gap-2 py-1">

          <BsArrowRight className="mt-2" /> Registration fee will be
          automatically deducted from your account balance.
        </div>
        <div className="flex gap-2 py-1">

          <BsArrowRight className="mt-2" /> Your account will be debitted each time a confirmation is triggered
        </div>
        <div className="flex gap-2 py-1">

          <BsArrowRight className="mt-2" /> Not enough funds? no worries, kindly
          make a deposit to your account and it will automatically reflect and
          open you up for activation.
        </div>
        <div className="flex gap-2 py-1 border-b mb-5">

          <BsArrowRight className="mt-2" /> Enough talking!.. let's get your
          earning from your referrals right away.
        </div>
        <div className="w-fit ml-auto">
          <Formbutton
            title="Confirm and proceed"
            loading={loading}
            handleSubmission={handleSubmission}
          />
        </div>
      </div>
    </ModalLayout>
  );
}
